import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { dateChanger, changeToViewDate, changeToViewDateTime, changeTimeDifferenceToDays } from '../helper/dateChanger';
import { calculateDateAge } from '../helper/helper';
import { setUserRole } from '../actions/setUserRole';
import moment from 'moment';
const stageMap = ["P1 Received", "Panel", "Panel Mount", "Inverter", "Commission", "Net Metering", "P4 Remaining", "Delivered", "Serviced", "Canceled", "Hold", "Truncated", "Not selected"];
const stageTestMap = ["Hold", "Initiation", "EB_Issue", "Loan_issue", "Contract", "Structure", "Commissioning", "Net_Metering", "closing", "Not selected"];

const colorMapping = {
	"Red": "#b80f0a",
	"Yellow": "#FDA50F",
	" ": "",
	"Green": "#099414",
	"NA": "#147275"
}

export const vendorDataName = ["NA", "Shoba Globs", "Shree Esteem India", "Jivana Energy", "Shine Solar", "Blueneba", "SH Power", "Wartan", "Greentech", "Backpost", "Saurtech", "E6 Energy", "Oneindig", "IMI Engineers", "Kubhan Engineers", "Mahamaya Enterprises", "Saurtech", "Smart Power", "Cyan Energy", "Wenergie", "Anshika Electricals", "Mesho Solution", "OmSolar", "WellSun", "SunGrip", "Habile Engineers", "Gurbani Solar", "Nexa", "Rosol Solar", "Telecor", "Energy Saving", "RS Solar", "Neca", "Energy Control", "Saur Tech", "Nando", "Kajla Solar", "UV solar", "Shagg solar", "SunPure", "Flamingo", "Udit/Sahil", "Iwe Solar", "Tajs Ent", "Kompetent", "Boond", "Chemitech", "SmartRoof", "Aryav", "Zivah", "SDSL", "Oakridge", "ZunRoof", "SunEnergy", "VolksEnergie", "Sirius", "SKG", "TouchLife", "AVS Solar", "REC", "Agias", "Rigava", "Stellar", "SK Solar", "GeoPower", "GM Trading", "Alien", "Milani", "Markanday", "HK Solar", "JK Energy", "Eko Solar", "Shiva Global", "Al Rana Traders", "Greensworth Energia", "Power InC ", "RMS Solar", "Solar World", "SVR Energy", "AS Enterprises", "Kiah Energies Pvt Ltd"];

export const columnsComplete = [
	{
		Header: 'quoteid',
		accessor: 'quoteid', // String-based value accessors!,
		Cell: row => (
			<div>
				<Link to={"/quoteid/" + row.value} onClick={(event) => { event.preventDefault(); window.open(window.location.origin + "/quoteid/" + row.value); }}>{row.value}</Link>
			</div>
		),
		filterMethod: (filter, row) => String(row[filter.id]).toLowerCase() === filter.value.toLowerCase()

	},
	{
		Header: 'name',
		accessor: 'name' // String-based value accessors!
	},
	{
		Header: 'phone',
		accessor: 'phone' // String-based value accessors!
	},
	{
		Header: 'email',
		accessor: 'email' // String-based value accessors!
	},
	{
		Header: 'responsible',
		accessor: 'responsible' // String-based value accessors!
	},
	// {
	// 	Header: 'siteid',
	// 	accessor: 'siteid' // String-based value accessors!
	// },
	{
		Header: 'state',
		accessor: 'state' // String-based value accessors!
	},
	{
		Header: 'city',
		accessor: 'city' // String-based value accessors!
	},
	{
		Header: 'address',
		accessor: 'address' // String-based value accessors!
	},
	{
		Header: 'size',
		accessor: 'selectedsize' // String-based value accessors!
	},
	{
		Header: 'lat',
		accessor: 'latitude' // String-based value accessors!
	},
	{
		Header: 'lon',
		accessor: 'longitude' // String-based value accessors!
	},
	{
		Header: 'inc',
		accessor: 'inc' // String-based value accessors!
	},
	{
		Header: 'vendor',
		accessor: 'vendor' // String-based value accessors!
	}
]

export const columnsComment = [
	{
		Header: 'Name',
		accessor: 'name',
		width: 130,
		style: {
			'whiteSpace': 'unset',
		}
	},
	{
		Header: 'Quote ID',
		accessor: 'quoteid',
		width: 130,
		filterMethod: (filter, row) => String(row[filter.id]).toLowerCase() === filter.value.toLowerCase()
	},
	{
		Header: 'Responsible',
		accessor: 'responsible',
		width: 130,
		style: {
			'whiteSpace': 'unset',
		}
	},
	{
		Header: 'Comment',
		accessor: 'text',
		width: 550,
		style: { 'whiteSpace': 'unset' }
	},
	{
		Header: 'Date',
		accessor: 'commentDate',
	}
]

export const columnsBill = [
	{
		Header: 'Quote Id',
		accessor: 'quoteid',
		filterMethod: (filter, row) => String(row[filter.id]).toLowerCase() === filter.value.toLowerCase()
	},
	{
		Header: 'Name',
		accessor: 'name'
	},
	{
		Header: 'Size',
		accessor: 'size'
	},
	{
		Header: 'Payment Type',
		accessor: 'paymenttype',
		width: 207
	},
	{
		Header: 'Amount',
		accessor: 'paymentamount'
	},
	{
		Header: 'Property Type',
		accessor: 'propertytype'
	},
	{
		Header: 'State',
		accessor: 'state'
	}
]


export const columnsNotification = [
	{
		Header: 'name',
		accessor: 'name',
		width: 130,
		style: {
			'whiteSpace': 'unset',
		}
	},
	{
		Header: 'quoteid',
		accessor: 'quoteid',
		width: 130,
		filterMethod: (filter, row) => String(row[filter.id]).toLowerCase() === filter.value.toLowerCase()
	},
	{
		Header: 'responsible',
		accessor: 'responsible',
		width: 130,
		style: {
			'whiteSpace': 'unset',
		}
	},
	{
		Header: 'notification',
		accessor: 'text',
		width: 550,
		style: { 'whiteSpace': 'unset' }
	},
	{
		Header: 'date',
		accessor: 'notificationdate'
	}
]

export const columnsorderstatus = [
	{
		Header: 'name',
		accessor: 'name'
	},
	{
		Header: 'quoteid',
		accessor: 'quoteid',
		filterMethod: (filter, row) => String(row[filter.id]).toLowerCase() === filter.value.toLowerCase()
	},
	{
		Header: 'expectedCompletionDate',
		accessor: 'expectedCompletionDate'
	},
	{
		Header: 'invertor',
		accessor: 'invertor'
	},
	{
		Header: 'netMetering',
		accessor: 'netMetering'
	},
	{
		Header: 'orderConfirmationInternal',
		accessor: 'orderConfirmationInternal'
	},
	{
		Header: 'panel',
		accessor: 'panel'
	},
	{
		Header: 'structure',
		accessor: 'structure'
	},
	{
		Header: 'subsidyApply',
		accessor: 'subsidyApply'
	},
	{
		Header: 'address',
		accessor: 'address'
	},
	{
		Header: 'city',
		accessor: 'city'
	}
]


export const columnspaymentstatus = [
	{
		Header: 'quoteid',
		accessor: 'quoteid',
		filterMethod: (filter, row) => String(row[filter.id]).toLowerCase() === filter.value.toLowerCase()
	},
	{
		Header: 'Payment Type',
		accessor: 'paymenttype'
	},
	{
		Header: 'payment',
		accessor: 'paymentamount'
	},
	{
		Header: 'Payment Date',
		accessor: 'paymentdate'
	},
	{
		Header: 'name',
		accessor: 'name'
	}
]


export const columnsstagestatus = [
	{
		Header: 'Quote Id',
		accessor: 'quoteid',
		Cell: row => (
			<div>
				<Link to={"/quoteid/" + row.value} onClick={(event) => { event.preventDefault(); window.open(window.location.origin + "/quoteid/" + row.value); }}>{row.value}</Link>
			</div>
		),
	},
	{
		Header: 'Name',
		accessor: 'name'
	},
	{
		Header: 'Size',
		accessor: 'size'
	},
	{
		Header: 'Client',
		headerStyle: {
			'color': 'white',
			'fontSize': '15px',
			'backgroundColor': '#60a1a5',
		},
		columns: [
			{
				Header: row => {
					return (
						<div data-toggle="tooltip" data-placement="bottom" data-html="true" title={checkStageToolTip(1)}>
							All
						</div>
					)
				},
				accessor: 'notificationC',
				'width': 80,
				Cell: row => {
					return checkStage(row);
				}
			},
			{
				Header: row => {
					return (
						<div data-toggle="tooltip" data-placement="bottom" data-html="true" title={checkStageToolTip(2)}>
							Commissioning
						</div>
					)
				},
				accessor: 'commissioningC',
				'width': 110,
				Cell: row => {
					return checkStage(row);
				}
			}
		]
	},
	{
		Header: 'Payment',
		headerStyle: {
			'color': 'white',
			'fontSize': '15px',
			'backgroundColor': '#70b6b9',
		},
		columns: [
			{
				Header: row => {
					return (
						<div data-toggle="tooltip" data-placement="bottom" data-html="true" title={checkStageToolTip(3)}>
							Zero Payment
						</div>
					)
				},
				accessor: 'payment0',
				'width': 100,
				Cell: row => {
					return checkStage(row);
				}
			},
			{
				Header: row => {
					return (
						<div data-toggle="tooltip" data-placement="bottom" data-html="true" title={checkStageToolTip(4)}>
							P1 Payment
						</div>
					)
				},
				accessor: 'payment1',
				'width': 80,
				Cell: row => {
					return checkStage(row);
				}
			},
			{
				Header: row => {
					return (
						<div data-toggle="tooltip" data-placement="bottom" data-html="true" title={checkStageToolTip(5)}>
							P2 Payment
						</div>
					)
				},
				accessor: 'payment2',
				'width': 80,
				Cell: row => {
					return checkStage(row);
				}
			},
			{
				Header: row => {
					return (
						<div data-toggle="tooltip" data-placement="bottom" data-html="true" title={checkStageToolTip(6)}>
							P3 Payment
						</div>
					)
				},
				accessor: 'payment3',
				'width': 80,
				Cell: row => {
					return checkStage(row);
				}
			},
			{
				Header: row => {
					return (
						<div data-toggle="tooltip" data-placement="bottom" data-html="true" title={checkStageToolTip(7)}>
							P4 Payment
						</div>
					)
				},
				accessor: 'payment4',
				'width': 100,
				Cell: row => {
					return checkStage(row);
				}
			}
		]
	},
	{
		Header: 'Progress',
		headerStyle: {
			'color': 'white',
			'fontSize': '15px',
			'backgroundColor': '#8fc9cc',
		},
		columns: [
			{
				Header: row => {
					return (
						<div data-toggle="tooltip" data-placement="bottom" data-html="true" title={checkStageToolTip(8)}>
							P1 Recieved
						</div>
					)
				},
				accessor: 'p1recieved',
				'width': 80,
				Cell: row => {
					return checkStage(row);
				}
			},
			{
				Header: row => {
					return (
						<div data-toggle="tooltip" data-placement="bottom" data-html="true" title={checkStageToolTip(9)}>
							Panels
						</div>
					)
				},
				accessor: 'panels',
				'width': 80,
				Cell: row => {
					return checkStage(row);
				}
			},
			{
				Header: row => {
					return (
						<div data-toggle="tooltip" data-placement="bottom" data-html="true" title={checkStageToolTip(10)}>
							Inverter
						</div>
					)
				},
				accessor: 'inverter',
				'width': 80,
				Cell: row => {
					return checkStage(row);
				}
			},
			{
				Header: row => {
					return (
						<div data-toggle="tooltip" data-placement="bottom" data-html="true" title={checkStageToolTip(11)}>
							Comissioning
						</div>
					)
				},
				accessor: 'commissioningP',
				'width': 110,
				Cell: row => {
					return checkStage(row);
				}
			}
		]
	}
]


// PER QUOTE ID

export const columnsPerNotification = [
	{
		Header: 'Date',
		accessor: 'date'
	},
	{
		Header: 'Who',
		accessor: 'responsible',
		width: 200,
		style: {
			'whiteSpace': 'unset',
		}
	},
	{
		Header: 'Notification',
		accessor: 'text',
		width: 680,
		style: { 'whiteSpace': 'unset' }
	}
]

export const columnsPerComments = [
	{
		Header: 'Time',
		accessor: 'time',
		show: false
	},
	{
		Header: 'Date',
		accessor: 'date'
	},
	{
		Header: 'Who',
		accessor: 'responsible',
		width: 200,
		style: {
			'whiteSpace': 'unset',
		}
	},
	{
		Header: 'Stage 1',
		accessor: 'stage1',
		width: 200,
		style: {
			'whiteSpace': 'unset',
		}
	},
	{
		Header: 'Stage 2',
		accessor: 'stage2',
		width: 200,
		style: {
			'whiteSpace': 'unset',
		}
	},
	{
		Header: 'Comment',
		accessor: 'text',
		width: 680,
		style: {
			whiteSpace: 'pre-line',
			textAlign: 'initial'
		}
	}
]

export const columnsEmail = [
	{
		Header: 'Date',
		accessor: 'date',
		Cell: row => (<div>{changeToViewDate(row.value)}</div>)
	},
	{
		Header: 'To',
		accessor: 'to',
	},
	{
		Header: 'From',
		accessor: 'fromName',
	},
	{
		Header: 'Cc',
		accessor: 'cc',
	},
	{
		Header: 'Subject',
		accessor: 'subject',
	},
	{
		Header: 'Text',
		accessor: 'mailText',
		style: {
			width: '200px'
		}
	},
	{
		Header: 'ViewHtml',
		accessor: 'mailId',
		Cell: row => (
			<div>
				<Link to={"/emailview/" + row.value} onClick={(event) => { event.preventDefault(); window.open(window.location.origin + "/emailview/" + row.value); }}>Click to View</Link>
			</div>
		),
		style: { 'whiteSpace': 'unset' }
	}
]


export const columnsCallData = [
	// {
	// 	Header : 'Epoch',
	// 	accessor : 'epoch',
	// 	Cell: row => (<div>{changeToViewDate(row.value)}</div>)
	// },
	{
		Header: 'Date',
		accessor: 'call_date',
		Cell: row => (<div>{changeToViewDate(+row.value)}</div>)
	},
	{
		Header: 'Customer No',
		accessor: 'customer_number',
	},
	{
		Header: 'Type',
		Header: row => {
			let { data } = row;
			return `Type (${data.length})`;
		},
		accessor: 'call_type',
	},
	{
		Header: 'Status',
		accessor: 'agent_number',
	},
	{
		Header: 'Duration',
		Header: row => {
			let { data } = row;
			let totalDuration = data.reduce((a, b) => a + (!isNaN(b.call_duration) ? parseInt(b.call_duration) : 0), 0);
			return `Duration (${(moment.utc(totalDuration * 1000).format('HH:mm:ss'))})`
		},
		accessor: 'call_duration',
		Cell: row => (moment.utc(row.value * 1000).format('HH:mm:ss'))
	},
]

export const columnsDeals = [
	{
		Header: 'Sales Date',
		accessor: 'salesdate', // String-based value accessors!
		Cell: row => (
			<div>
				{changeToViewDate(row.value)}
			</div>
		),
		customName: "Sales Date"
	},
	{
		Header: row => {
			let data = row.data.map(val => 1);
			return (<div>Quote Id {data.reduce((a, b) => a + b, 0)}</div>)
		},
		accessor: 'quoteid', // String-based value accessors!
		Cell: row => (
			<div>
				<Link to={"/quoteid/" + row.value} onClick={(event) => { event.preventDefault(); window.open(window.location.origin + "/quoteid/" + row.value); }}>{row.value}</Link>
			</div>
		),
		style: { 'whiteSpace': 'unset' },
		customName: "Quote Id"
	},
	{
		Header: 'Name',
		accessor: 'username', // String-based value accessors!
		customName: "Name"
	},
	{
		Header: row => {
			let data = row.data.map(val => val.size);
			return (<div>Size {data.reduce((a, b) => a + b, 0)}</div>)
		},
		accessor: 'size',
		customName: "Size"
	},
	{
		Header: 'Stage',
		accessor: 'stage',
		customName: "Stage"
	},
	{
		Header: 'Stage 1',
		accessor: 'stage1',
		customName: "Stage 1"
	},
	{
		Header: 'Stage 2',
		accessor: 'stage2',
		customName: "Stage 2"
	},
	{
		Header: 'City',
		accessor: 'city', // String-based value accessors!
		customName: "City"
	},
	{
		Header: 'State',
		accessor: 'stateD',
		customName: 'State'
	},
	// {
	// 	Header: 'Total Payment',
	// 	accessor: 'totalPayment'
	// },
	{
		Header: 'Subsidy',
		accessor: 'subsidyD',
		customName: 'Subsidy'
	},
	{
		Header: 'Subsidy Status',
		accessor: 'subsidyStatus',
		customName: 'Subsidy Status'
	},
	{
		Header: 'Paid %',
		accessor: 'paidPercentage',
		customName: 'Paid %'
	},
	{
		Header: 'Invoice No.',
		accessor: 'invoiceText',
		customName: 'Invoice No'
	},
	{
		Header: 'MainT. Req.',
		accessor: 'maintenanceRequired',
		customName: 'Maintenance Required'
	},
	{
		Header: 'Invoice Date',
		accessor: 'invoiceDate',
		Cell: row => {
			return (
				<div>
					{row.value ? changeToViewDate(row.value) : ""}
				</div>
			)
		},
		customName: 'Invoice Date'
	},
	{
		Header: 'V1',
		accessor: 'vendor',
		customName: 'V1'
	},
	{
		Header: 'V2',
		accessor: 'inc',
		customName: 'V2'
	},
	{
		Header: 'Responsible',
		accessor: 'responsibleName',
		customName: 'Responsible'
	},
	{
		Header: 'Action Date',
		accessor: 'nextactiondate',
		customName: 'Action Date'
	},
	{
		Header: 'Ref Date',
		accessor: 'refDate',
		Cell: row => {
			return (
				<div>
					{(row.value && row.value !== "NA") ? changeToViewDate(row.value) : ""}
				</div>
			)
		},
		customName: 'Ref Date'
	},
	{
		Header: 'Elevation Type',
		accessor: 'elevationtype', // String-based value accessors!
		customName: 'Elevation Type'
	},
	{
		Header: 'System Type',
		accessor: 'type', // String-based value accessors!
		customName: 'System Type'
	},
	{
		Header: 'Loan Issue',
		accessor: 'financingIssue',
		customName: 'Loan Issue'
	},
	{
		Header: 'Structure',
		accessor: 'structureExpectedDate',
		Cell: row => {
			return (
				<div>
					{row.value ? changeToViewDate(row.value) : ""}
				</div>
			)
		},
		customName: 'Structure'
	},
	{
		Header: 'Panel Prom',
		accessor: 'panelPromised',
		customName: 'Panel Promised'
	},
	{
		Header: 'Panel Del',
		accessor: 'deliveredpanel',
		customName: 'Panel Del'
	},
	{
		Header: 'Panel',
		accessor: 'panelExpectedDate',
		Cell: row => {
			return (
				<div>
					{row.value ? changeToViewDate(row.value) : ""}
				</div>
			)
		},
		customName: 'Panel'
	},
	{
		Header: 'Inv. Prom.',
		accessor: 'inverterPromised',
		customName: 'Inverter Promised'
	},
	{
		Header: 'Inv. Del',
		accessor: 'deliveredInverter',
		customName: 'Inverter Del'
	},
	{
		Header: 'Inverter',
		accessor: 'invertorExpectedDate',
		Cell: row => {
			return (
				<div>
					{row.value ? changeToViewDate(row.value) : ""}
				</div>
			)
		},
		customName: 'Inverter'
	},
	{
		Header: 'EB_Name',
		accessor: 'ebIssueName',
		customName: 'Eb Name'
	},
	{
		Header: 'EB_Load',
		accessor: 'ebIssueLoad',
		customName: 'Eb Load'
	},
	{
		Header: 'EB_Type',
		accessor: 'ebIssueType',
		customName: 'Eb Type'
	},
	{
		Header: 'EB_Phase',
		accessor: 'ebIssuePhase',
		customName: 'Eb Phase'
	},
	{
		Header: 'Property Type',
		accessor: 'propertytype',
		customName: 'Property Type'
	},
	{
		Header: row => {
			let { data } = row;
			let total = data.reduce((a, b) => a + (b.totalPayment ? parseInt(b.totalPayment) : 0), 0);
			return `Total Payment (${total})`;
		},
		accessor: 'totalPayment',
		customName: 'Total Payment'
	},
	{
		Header: row => {
			let { data } = row;
			let total = data.reduce((a, b) => a + (b.totalPrice ? parseInt(b.totalPrice) : 0), 0);
			return `Total Price (${total})`;
		},
		accessor: 'totalPrice',
		customName: 'Total Price'
	},
	{
		Header: 'Net Metering Date',
		accessor: 'netMeterDate',
		Cell: row => {
			return (
				<div>
					{row.value ? changeToViewDate(row.value) : ""}
				</div>
			)
		},
		customName: 'Net Metering Date'
	},
	{
		Header: 'Net Metering Applied Date',
		accessor: 'netMeteringAppliedDate',
		Cell: row => {
			return (
				<div>
					{row.value ? changeToViewDate(row.value) : ""}
				</div>
			)
		},
		customName: 'Net Metering Applied Date'
	},
	{
		Header: 'Commissioning Date',
		accessor: 'commissioningDate',
		Cell: row => {
			return (
				<div>
					{row.value ? changeToViewDate(row.value) : ""}
				</div>
			)
		},
		customName: 'Commissioning Date'
	},
	{
		Header: 'Net Metering Stage',
		accessor: 'netMeteringStage',
		customName: 'Net Metering Stage'
	},
	{
		Header: 'Mpp Verification Status',
		accessor: 'mppVerificationStatus',
		customName: 'Mpp Verification Status'
	},
	{
		Header: 'Feasibility',
		accessor: 'feasibility',
		customName: 'Feasibility'
	},
	{
		Header: 'RMS Installed',
		accessor: 'rmsInstalled',
		customName: 'RMS Installed'
	},
	{
		Header: 'RMS Status',
		accessor: 'rmsStatus',
		customName: 'RMS Status'
	},
	{
		Header: 'RMS Device Type',
		accessor: 'rmsDeviceType',
		customName: 'RMS Device Type'
	},
	{
		Header: 'Site Visit',
		accessor: 'siteVisit',
		customName: 'Site Visit'
	},
	{
		Header: 'ZunPrime',
		accessor: 'zunPrime',
		customName: 'ZunPrime'
	},
	{
		Header: 'Layout Approval Date',
		accessor: 'approvalDate',
		customName: 'Layout Approval Date',
		Cell: row => (
			<div>
				{row.value ? changeToViewDate(row.value) : ''}
			</div>
		),
	},
	{
		Header: 'Fabricator',
		accessor: 'structureVendor',
		customName: 'structureVendor'
	},
	{
		Header: 'AMC',
		accessor: 'amc',
		customName: 'amc'
	},
	{
		Header: 'Days Since 90% Payment Completion',
		accessor: 'completePaymentDate',
		customName: 'Days Since 90% Payment Completion'
	},
	{
		Header: 'Total Inbound Calls',
		accessor: 'totalInboundCalls',
		customName: 'Total Inbound Calls'
	},
	{
		Header: 'Total Call Duration',
		accessor: 'totalCallDuration',
		customName: 'Total Call Duration',
		Cell: row => (`${parseInt(row.value / 60)}m ${row.value % 60}s`)
	},
	{
		Header: 'Quality Visit',
		accessor: 'qualityVisit',
		customName: 'Quality Visit'
	},
	{
		Header: 'PE Visits',
		accessor: 'peVisitCount',
		customName: 'PE Visits'
	},
	{
		Header: 'Special Req',
		accessor: 'specialRequirement',
		customName: 'Special Req'
	},
	{
		Header: 'Tender Year',
		accessor: 'tenderYear',
		customName: 'Tender Year'
	},
	{
		Header: 'GST_B',
		accessor: 'GST_B',
		customName: 'GST_B'
	},
	{
		Header: 'Billing Address',
		accessor: 'BillingAddress_B',
		customName: 'Billing Address'
	},
	{
		Header: 'Billing Name',
		accessor: 'connectionHolderName_B',
		customName: 'Billing Name'
	},
	{
		Header: 'Size_del',
		accessor: 'size_del',
		customName: 'Size_del'
	},
	{
		Header: 'Moved by',
		accessor: 'idMovedBy_D',
		customName: 'Moved by'
	},
	{
		Header: 'Phase',
		accessor: 'phase',
		customName: 'Phase'
	},
	{
		Header: 'First Layout Shared Date',
		accessor: 'firstLayoutSharedDate',
		Cell: row => {
			return (
				<div>
					{row.value ? changeToViewDate(row.value) : ""}
				</div>
			)
		},
		customName: 'First Layout Shared Date'
	},
	{
		Header: 'EB Issue Applied Date',
		accessor: 'ebIssueAppliedDate',
		Cell: row => {
			return (
				<div>
					{row.value ? changeToViewDate(row.value) : ""}
				</div>
			)
		},
		customName: 'EB Issue Applied Date'
	},
	{
		Header: 'EB Issue Resolution Date',
		accessor: 'ebIssueResolutionDate',
		Cell: row => {
			return (
				<div>
					{row.value ? changeToViewDate(row.value) : ""}
				</div>
			)
		},
		customName: 'EB Issue Resolution Date'
	},
	{
		Header: 'Loan Resolution Date',
		accessor: 'loanIssueDate',
		Cell: row => {
			return (
				<div>
					{row.value ? changeToViewDate(row.value) : ""}
				</div>
			)
		},
		customName: 'Loan Resolution Date'
	},
	{
		Header: 'Subsidy Applied Date',
		accessor: 'subsidyAppliedDate',
		Cell: row => {
			return (
				<div>
					{row.value ? changeToViewDate(row.value) : ""}
				</div>
			)
		},
		customName: 'Subsidy Applied Date'
	},
	{
		Header: 'Subsidy Approval Date',
		accessor: 'subsidyApprovalDate',
		Cell: row => {
			return (
				<div>
					{row.value ? changeToViewDate(row.value) : ""}
				</div>
			)
		},
		customName: 'Subsidy Approval Date'
	},
	{
		Header: 'Payment 49% Date',
		accessor: 'payment49Date',
		Cell: row => {
			return (
				<div>
					{row.value ? changeToViewDate(row.value) : ""}
				</div>
			)
		},
		customName: 'Payment 49% Date'
	},
	{
		Header: 'Payment 99% Date',
		accessor: 'payment99Date',
		Cell: row => {
			return (
				<div>
					{row.value ? changeToViewDate(row.value) : ""}
				</div>
			)
		},
		customName: 'Payment 99% Date'
	},
	{
		Header: 'V2 Handover Date',
		accessor: 'v2HandoverDate',
		Cell: row => {
			return (
				<div>
					{row.value ? changeToViewDate(row.value) : ""}
				</div>
			)
		},
		customName: 'V2 Handover Date'
	},
	{
		Header: 'Loan Provider',
		accessor: 'loanProvider',
		customName: 'Loan Provider'
	}
]

export const incentiveColumn =
	[
		{
			Header: 'Date',
			accessor: 'date', // String-based value accessors!,
			csvAccessor: 'viewDate',
			Cell: row => (<div>{changeToViewDate(parseInt(row.value))}</div>)
		},
		{
			Header: 'Quote Id',
			accessor: 'quoteId',
			Cell: row => (
				<div>
					<Link to={"/quoteid/" + row.value} onClick={(event) => { event.preventDefault(); window.open(window.location.origin + "/quoteid/" + row.value); }}>{row.value}</Link>
				</div>
			),
			style: { 'whiteSpace': 'unset' }
		},
		{
			Header: 'Type',
			accessor: 'type'
		},
		{
			Header: 'Subtype',
			accessor: 'subtype'
		},
		{
			Header: 'City',
			accessor: 'city'
		},
		{
			Header: 'Responsible',
			accessor: 'responsible'
		},
		{
			Header: 'Team',
			accessor: 'team'
		},
		{
			Header: row => {
				let data = row.data.map(val => Math.round(parseFloat(val.value) * 100) / 100);
				return (<div>Value {data.reduce((a, b) => a + b, 0)}</div>)
			},
			csvHeader: 'Value',
			accessor: 'value'
		}
	]

export const columnsFMS = [
	{
		Header: 'Date',
		accessor: 'createdOn',
		Cell: row => (<div>{changeToViewDate(row.value)}</div>)
	},
	{
		Header: 'Name',
		accessor: 'name',
	},
	{
		Header: 'Type',
		accessor: 'folderType'
	},
	{
		Header: 'Sub Type',
		accessor: 'type'
	},
	{
		Header: 'Version',
		accessor: 'versionName',
	}
]

export const columnsEmailAnalytics = [
	{
		Header: 'Date',
		accessor: 'date',
		Cell: row => (<div>{changeToViewDate(row.value)}</div>)
	},
	{
		Header: 'Quote Id',
		accessor: 'quoteId',
		Cell: row => (
			<div>
				<Link to={"/quoteid/" + row.value} onClick={(event) => { event.preventDefault(); window.open(window.location.origin + "/quoteid/" + row.value); }}>{row.value}</Link>
			</div>
		),
		style: { 'whiteSpace': 'unset' }
	},
	{
		Header: 'Name',
		accessor: 'name',
		Cell: row => (
			<div>
				<Link to={"/emailview/" + row.value.split("__")[1]} onClick={(event) => { event.preventDefault(); window.open(window.location.origin + "/emailview/" + row.value.split("__")[1]); }}>{row.value.split("__")[0]}</Link>
			</div>
		),
	},
	{
		Header: 'Email',
		accessor: 'fromName',
		width: 250,
	},
	{
		Header: 'Team',
		accessor: 'teamId',
	},
	{
		Header: 'Subject',
		accessor: 'subject'
	},
	{
		Header: 'Text',
		accessor: 'mailText',
		width: 400,
	}
]

export const columnsEmailAccounts = [
	{
		Header: 'Date',
		accessor: 'date',
		Cell: row => (<div>{changeToViewDate(row.value)}</div>)
	},
	{
		Header: 'Name',
		accessor: 'fromName',
		Cell: row => (
			<div>
				{row.value.split("__")[0]}
			</div>
		),

	},
	{
		Header: 'Email',
		accessor: 'fromName',
		width: 250,
	},
	{
		Header: 'Subject',
		accessor: 'subject'
	},
	{
		Header: 'Text',
		accessor: 'mailText',
		width: 400,
	},
	{
		Header: 'VIEW',
		accessor: 'key',
		Cell: row => (
			<div>
				<Link to={"/emailviewaccounts/" + row.value} onClick={(event) => { event.preventDefault(); window.open(window.location.origin + "/emailviewaccounts/" + row.value); }}><button type="button" className="btn btn-success">VIEW</button></Link>
			</div>
		),
	}
]



export const columnsClientAnalytics = [
	{
		Header: 'Date',
		accessor: 'date',
		Cell: row => (<div>{changeToViewDateTime(row.value)}</div>)
	},
	{
		Header: 'Quote Id',
		accessor: 'quoteId',
		Cell: row => (
			<div>
				<Link to={"/quoteid/" + row.value} onClick={(event) => { event.preventDefault(); window.open(window.location.origin + "/quoteid/" + row.value); }}>{row.value}</Link>
			</div>
		),
		style: { 'whiteSpace': 'unset' }
	},
	{
		Header: 'Name',
		accessor: 'name',
	},
	{
		Header: 'From',
		accessor: 'mobile'
	},
	{
		Header: 'To',
		accessor: 'on',
	},
	{
		Header: 'Type',
		accessor: 'type',
	},
	{
		Header: 'Durations',
		accessor: 'duration',
	}
]

export const filesAllColumn = [
	{
		Header: 'Quote ID',
		accessor: 'quoteid',
		width: 100,
		Cell: row => (
			<div>
				<Link to={"/quoteid/" + row.value} onClick={(event) => { event.preventDefault(); window.open(window.location.origin + "/quoteid/" + row.value); }}>{row.value}</Link>
			</div>
		),
		style: { 'whiteSpace': 'unset' },
		filterMethod: (filter, row) => String(row[filter.id]).toLowerCase() === filter.value.toLowerCase()
	},
	{
		Header: 'Full Name',
		accessor: 'user',
		width: 100,
		style: { 'whiteSpace': 'unset' }
	},
	{
		Header: 'Size',
		accessor: 'size',
		width: 100,
		style: { 'whiteSpace': 'unset' }
	}
]


function checkStage(row) {
	switch (row.value) {
		case 1:
			return (
				<div className="stageStatus redStage">
					<i className="fas fa-exclamation" style={{ 'padding': '7px 1px' }}></i></div>
			)
		case 2:
			return (
				<div className="stageStatus yellowStage" ><i className="fas fa-exclamation" style={{ 'padding': '7px 1px' }}></i></div>
			)
		case 3:
			return (
				<div className="stageStatus greenStage"><i className="fas fa-check" style={{ 'padding': '7px 1px' }}></i></div>
			)
		default:
			return (
				<div style={{
					'backgroundColor': 'white',
					'width': 30,
					'borderRadius': 10,
					'height': '100%',
					'margin': '0 auto'
				}}></div>
			)
	}
}

function checkStageToolTip(value) {
	switch (value) {
		case 1:
			return `Stage is in P1 Received, Panel, Panel Mount, Inverter, Comissioning or Net-Metering\nRed : Last notfication > 10\nYellow : Last notfication > 7`
		case 2:
			return `Stage is in Panel, Panel Mount, Inverter or Comissioning\nRed : Last notfication > 7 and Last Payment >10 and Payment% >65`
		case 3:
			return `Stage is P1 recieved\nRed: Payment % = 0`;
		case 4:
			return `Stage is P1 recieved\nRed: Payment % < 15`;
		case 5:
			return `Stage is Panel, Panel Mount\nRed: Payment % < 65`;
		case 6:
			return `Stage is Panel Mount, Inverter, Comissioning or Net Metering\nRed: Payment % < 93`;
		case 7:
			return `Stage is P4 remaining\nRed: Payment % < 99`;
		case 8:
			return `Stage is P1 Recieved\nRed: Payment % >= 15 and Sale Date >10 and Structure Date is missing\n  OR Payment % < 65 and Sale Date >30 and Structure Date is not missing\nYellow: Payment % >= 15 and Sale Date >7 and Structure Date is missing\n  OR Payment % < 65 and Sale Date >25 and Structure Date is not missing`;
		case 9:
			return `Stage is P1 Recieved or Panel\nRed: Payment % >= 65 and Ref. Date >10 and Panel_Del is missing\nYellow: Payment % >= 65 and Ref. Date >7 and Panel_Del is missing`;
		case 10:
			return `Stage is Panel, Panel Mount or Inverter\nRed: Payment % >= 92 and Ref. Date >25 and Inverter_Del is missing\nYellow: Payment % >= 92 and Ref. Date >20 and Inverter_Del is missing`;
		case 11:
			return `Stage is Inverter or Comissioning\nRed: Payment % >= 92 and Ref. Date >25 and Inverter_Del is not missing\nYellow: Payment % >= 92 and Ref. Date >20 and Inverter_Del is not missing`;
		default:
			return "";
	}
}

export const columnsSalesAdmin = [
	{
		Header: 'Sales Date',
		accessor: 'salesDate', // String-based value accessors!
		Cell: row => (
			<div>{changeToViewDate(row.value)}</div>
		),
		type: '0'
	},
	{
		Header: row => {
			let data = row.data.map(val => 1);
			return (<div>Quote Id {data.reduce((a, b) => a + b, 0)}</div>)
		},
		accessor: 'quoteId',
		type: '3'
	},
	{
		Header: 'Name',
		accessor: 'name',
		type: '0'
	},
	{
		Header: row => {
			let data = row.data.map(val => val.size);
			return (<div>Size {data.reduce((a, b) => a + b, 0)}</div>)
		},
		accessor: 'size',
		type: '1'
	},
	{
		Header: 'Stage',
		accessor: 'stage',
		type: '2'
	},
	{
		Header: 'State',
		accessor: 'state',
		type: '2'
	},
	{
		Header: 'City',
		accessor: 'city',
		type: '2'
	},
	{
		Header: 'Elevation Type',
		accessor: 'elevationType',
		type: '2'
	},
	{
		Header: 'System Type',
		accessor: 'systemType',
		type: '2'
	},
	{
		Header: 'V1',
		accessor: 'v1',
		type: '0',
	},
	{
		Header: 'V2',
		accessor: 'v2',
		type: '0',
	},
	{
		Header: 'Sales Person',
		accessor: 'employeeId',
		type: '2',
		width: 250
	},
	{
		Header: 'Subsidy Value',
		accessor: 'subsidyValue',
		type: '3'
	},
	{
		Header: 'Subsidy',
		accessor: 'subsidy',
		type: '2'
	},
	{
		Header: 'Total Sale',
		accessor: 'totalSale',
		type: '0'
	},
	{
		Header: 'Customer Payment',
		accessor: 'finalPrice',
		type: '3'
	},
	{
		Header: 'Revenue Type',
		accessor: 'revenueType',
		type: '2'
	},
	{
		Header: 'Revenue Rate',
		accessor: 'revenueRate',
		type: '1'
	},
	{
		Header: 'Total Revenue',
		accessor: 'revenueTotal',
		type: '0'
	},
	{
		Header: 'Action',
		accessor: 'salesApprovedStatus_D',
		type: '4',
		showDefault: true
	}
]

export const columnsSalesAdminDownload = [
	{
		label: 'Responsible',
		key: 'employeeId'
	},
	{
		label: 'Sales Date',
		key: 'salesDate'
	},
	{
		label: 'Customer Id',
		key: 'quoteId',
	},
	{
		label: 'Name',
		key: 'name'
	},
	{
		label: 'Revenue Type',
		key: 'revenueType',
	},
	{
		label: 'V1',
		key: 'v1',
	},
	{
		label: 'V2',
		key: 'v2',
	},
	{
		label: 'Status',
		key: 'stage'
	},
	{
		label: 'Size',
		key: 'size'
	},
	{
		label: 'Customer Pays',
		key: 'finalPrice'
	},
	{
		label: 'Subsidy',
		key: 'subsidyKey'
	},
	{
		label: 'Any Other Payment',
		key: 'anyOtherPayment'
	},
	{
		label: 'Total Sales Value',
		key: 'totalSale'
	},
	{
		label: 'Revenue Rate',
		key: 'revenueRate'
	},
	{
		label: 'Booked Revenue',
		key: 'revenueTotal'
	},
	{
		label: 'City',
		key: 'city'
	},
	{
		label: 'State',
		key: 'state'
	},
	{
		label: 'Type',
		key: 'elevationType'
	}
]

export const columnPaymentReference = [
	{
		Header: 'Select',
		accessor: 'checkbox',
		sortable: false
	},
	{
		Header: 'Id',
		accessor: 'requestKey'
	},
	{
		Header: 'Date',
		accessor: 'date',
		Cell: row => <div>{changeToViewDateTime(row.value)}</div>
	},
	{
		Header: 'Quote Id',
		accessor: 'quoteId',
		Cell: row => {
			return (row.original.quoteId ? <div>
				<Link to={"/quoteid/" + row.value} onClick={(event) => { event.preventDefault(); window.open(window.location.origin + "/quoteid/" + row.value); }}>{row.value}</Link>
			</div> : (row.original.type || "")
			)
		}
	},
	{
		Header: 'Name',
		accessor: 'name',
	},
	{
		Header: 'Vendor',
		accessor: 'vendor',
	},
	{
		Header: 'Responsible',
		accessor: 'responsible',
	},
	{
		Header: 'Transaction Type',
		accessor: 'transactionType',
	},
	{
		Header: 'Size (kw)',
		accessor: 'inverterSize',
	},
	{
		Header: row => {
			let { data } = row;
			let total = data.reduce((a, b) => a + ((b.price && !isNaN(b.price)) ? parseFloat(b.price) : 0), 0);
			return `Amount (${parseFloat(total).toFixed(1)})`;
		},
		accessor: 'price',
		Cell: row => <div>{parseFloat(row.value).toFixed(1)}</div>
	},
	{
		Header: 'Payment Type',
		accessor: 'paymentType',
	},
	{
		Header: 'Type',
		accessor: 'businessType',
	},
	{
		Header: 'Approve',
		accessor: 'approvalStatus',
	}
]

export const vendorPaymentQuoteIdColumns = [
	{
		Header: 'Date',
		accessor: 'date',
		Cell: row => <div>{changeToViewDateTime(row.value)}</div>
	},
	{
		Header: 'Vendor',
		accessor: 'vendorName',
	},
	{
		Header: 'Transaction Type',
		accessor: 'transactionType',
	},
	{
		Header: 'Size',
		accessor: 'size',
	},
	{
		Header: row => {
			let { data } = row;
			let total = data.reduce((a, b) => a + ((b.price && !isNaN(b.price)) ? parseFloat(b.price) : 0), 0);
			return `Amount (${parseFloat(total).toFixed(1)})`;
		},
		accessor: 'amount',
		Cell: row => <div>{parseFloat(row.value).toFixed(1)}</div>
	},
	{
		Header: 'Status',
		accessor: 'status',
	}
]

export const columnInvoices = [
	{
		Header: 'Date',
		accessor: 'date',
		Cell: row => <div>{changeToViewDateTime(row.value)}</div>
	},
	{
		Header: 'Quote Id',
		accessor: 'quoteId',
		Cell: row => (
			<div>
				<Link to={"/quoteid/" + row.value} onClick={(event) => { event.preventDefault(); window.open(window.location.origin + "/quoteid/" + row.value); }}>{row.value}</Link>
			</div>
		)
	},
	{
		Header: 'Name',
		accessor: 'name',
	},
	{
		Header: 'Vendor',
		accessor: 'vendor',
	},
	{
		Header: 'Transaction Type',
		accessor: 'transactionType',
	},
	{
		Header: 'Invoice Required',
		accessor: 'invoiceStatus',
		style: { 'whiteSpace': 'unset' }
	},
	{
		Header: 'Invoice Approval',
		accessor: 'approvalStatus',
		style: { 'whiteSpace': 'unset' }
	}
]

export const columnPaymentCompleteReference = [
	{
		Header: 'Date',
		accessor: 'date',
		csvAccessor: 'dateView',
		Cell: row => <div>{changeToViewDateTime(row.value)}</div>
	},
	{
		Header: 'Type',
		accessor: 'transactionType'
	},
	{
		Header: 'Responsible',
		accessor: 'responsible'
	},
	{
		Header: 'Amount',
		accessor: 'price',
		Cell: row => <div>{parseFloat(row.value).toFixed(1)}</div>
	},
	{
		Header: 'Reference',
		accessor: 'reference'
	},
	{
		Header: 'Quote Id',
		accessor: 'quoteId',
		Cell: row => (
			<div>
				<Link to={"/quoteid/" + row.value} onClick={(event) => { event.preventDefault(); window.open(window.location.origin + "/quoteid/" + row.value); }}>{row.value}</Link>
			</div>
		)
	},
	{
		Header: 'Accepted On',
		accessor: 'acceptedOn',
		csvAccessor: 'acceptedOnView',
		Cell: row => <div>{row.value ? changeToViewDateTime(row.value) : ""}</div>
	},
	{
		Header: 'Bank',
		accessor: 'bankName'
	},
	{
		Header: 'Comments',
		accessor: 'comments',
		Cell: row => row.value ? <div data-toggle="tooltip" data-placement="bottom" data-html="true" title={row.value}>{row.value.substring(0, 100)}</div> : ""
	}
]

export const columnBanks = [
	{
		Header: 'Date',
		accessor: 'date',
		csvAccessor: 'viewDate',
		Cell: row => <div>{changeToViewDateTime(row.value)}</div>
	},
	{
		Header: 'Vendor',
		accessor: 'vendorName'
	},
	{
		Header: 'Amount',
		accessor: 'price',
		Cell: row => <div>{parseFloat(row.value).toFixed(1)}</div>
	},
	{
		Header: 'Reference',
		accessor: 'reference'
	},
	{
		Header: 'Bank',
		accessor: 'bankName'
	},
	{
		Header: 'Comments',
		accessor: 'comments',
		Cell: row => row.value ? <div data-toggle="tooltip" data-placement="bottom" data-html="true" title={row.value}>{row.value.substring(0, 100)}</div> : ""
	}
]

export const columnPaymentSummary = [
	{
		Header: 'Vendor',
		accessor: 'vendor'
	},
	{
		Header: 'Payment',
		accessor: 'payment',
		Cell: row => <div>{parseFloat(row.value).toFixed(1)}</div>
	}
];

export const panelsInventory = [
	{
		Header: 'Vendor',
		accessor: 'vendorName'
	},
	{
		Header: 'Brand',
		accessor: 'brand',
	},
	{
		Header: 'Make',
		accessor: 'make',
	},
	{
		Header: row => {
			let { data } = row;
			let total = data.reduce((a, b) => a + (b.make && b.make.split(' ')[1] && b.count ? parseFloat(b.make.split(' ')[1] * b.count) : 0), 0);
			return `Count Total - ${parseInt(total / 1000)} kW`;
		},
		accessor: 'count',
	}
];

export const inverterInventory = [
	{
		Header: 'Vendor',
		accessor: 'vendorName'
	},
	{
		Header: 'Brand',
		accessor: 'brand',
	},
	{
		Header: 'Size',
		accessor: 'inverterSize',
	},
	{
		Header: 'Phase',
		accessor: 'phase',
	},
	{
		Header: row => {
			let { data } = row;
			let total = data.reduce((a, b) => a + (b.inverterSize && b.count ? parseInt(b.inverterSize * b.count) : 0), 0);
			return `Count Total - ${total} kW`;
		},
		accessor: 'count',
	}
]

export const acdbInventory = [
	{
		Header: 'Vendor',
		accessor: 'vendorName'
	},
	{
		Header: 'City',
		accessor: 'city',
	},
	{
		Header: 'Make',
		accessor: 'make',
	},
	{
		Header: 'Count',
		accessor: 'count',
	}
];

export const columnVendorManagement = [
	// {
	// 	Header: 'name',
	// 	accessor: 'name',
	// 	type: 1
	// },
	// {
	// 	Header: 'Acc. Number',
	// 	accessor: 'accountNumber',
	// 	type: 1
	// },
	// {
	// 	Header: 'Address',
	// 	accessor: 'address',
	// 	type: 1
	// },
	// {
	// 	Header: 'Bank Name',
	// 	accessor: 'bank',
	// 	type: 1
	// },
	// {
	// 	Header: 'Company Type',
	// 	accessor: 'companyType',
	// 	type: 2
	// },
	// {
	// 	Header: 'Director',
	// 	accessor: 'director',
	// 	type: 1
	// },
	// {
	// 	Header: 'GST',
	// 	accessor: 'gst',
	// 	type: 1
	// },
	// {
	// 	Header: 'pancard',
	// 	accessor: 'pancard',
	// 	type: 1
	// },
	// {
	// 	Header: 'IFSC',
	// 	accessor: 'ifsc',
	// 	type: 1
	// },
	// {
	// 	Header: 'POC Name',
	// 	accessor: 'pocname',
	// 	type: 1
	// },
	// {
	// 	Header: 'POC Phone 1',
	// 	accessor: 'pocphone1',
	// 	type: 1
	// },
	// {
	// 	Header: 'POC Phone 2',
	// 	accessor: 'pocphone2',
	// 	type: 1
	// },
	{
		Header: 'Inverter',
		accessor: 'inverterPermission',
		type: 4
	},
	{
		Header: 'Panel',
		accessor: 'panelPermission',
		type: 4
	},
	{
		Header: 'Structure',
		accessor: 'structurePermission',
		type: 4
	},
	{
		Header: 'V1',
		accessor: 'v1Permission',
		type: 4
	},
	{
		Header: 'V2',
		accessor: 'v2Permission',
		type: 4
	},

]



export const columnsComplaint = [
	{
		Header: 'Date',
		accessor: 'epoch',
		Cell: row => (<div>{changeToViewDate(row.value)}</div>)
	},
	{
		Header: 'Quote Id',
		accessor: 'quoteId',
		Cell: row => (
			<div>
				<Link to={"/quoteid/" + row.value} onClick={(event) => { event.preventDefault(); window.open(window.location.origin + "/quoteid/" + row.value); }}>{row.value}</Link>
			</div>
		),
		style: { 'whiteSpace': 'unset' }
	},
	{
		Header: 'Phone Number',
		accessor: 'phoneNumber',
	},
	{
		Header: 'Ticket Id',
		accessor: 'ticketId',
	},
	{
		Header: 'City',
		accessor: 'city',
	},
	// {
	// 	Header : 'comment',
	// 	accessor : 'comment',

	// },
	{
		Header: 'Complaint Type',
		accessor: 'type',
	},
	{
		Header: 'Sub Type',
		accessor: 'subType',
	},
	{
		Header: 'Source',
		accessor: 'source',
		Cell: ({ row, original }) => {
			if (!original.source) {
				original.source = 'website';
			}
			return (<div>{original.source}</div>)
		}
	},
	{
		Header: 'Due Date',
		accessor: 'dueDate',
		Cell: ({ row, original }) => {
			let dueDate = '';
			if (original.dueDate) {
				dueDate = changeToViewDate(original.dueDate);
			} else {
				dueDate = new Date(original.epoch);
				dueDate.setDate(dueDate.getDate() + 2);
				dueDate.setHours(23);
				dueDate.setMinutes(59);
				dueDate.setSeconds(59);
				original.dueDate = dueDate.getTime();
				dueDate = 1563215399000;
				original.dueDate = dueDate;
				dueDate = changeToViewDate(dueDate);
			}
			return (<div>
				{dueDate}
			</div>);
		}
	},
	{
		Header: 'Complaint Removed',
		accessor: 'removed',
		Cell: row => (<div>{(row.value) ? (row.value == 1 ? 'Yes' : 'NA') : 'No'}</div>)
	},
	// {
	// 	Header : 'Last Action',
	// 	accessor : 'text',
	// },
	// {
	// 	Header : 'Updated By',
	// 	accessor : 'updatedByName',
	// },
	// {
	// 	Header : 'Update Time',
	// 	accessor : 'updateTime',
	// 	Cell: row => (<div>{changeToViewDate(row.value)}</div>)
	// },
	{
		Header: 'Status',
		accessor: 'status',
	}
];


export const peTaskColumns = [
	{
		Header: 'Date',
		accessor: 'date',
		Cell: row => (<div>{moment(row.value, 'YYYYMMDD').format('DD MMM, YYYY')}</div>)
	},
	{
		Header: 'Quote Id',
		accessor: 'quoteId',
		Cell: row => (
			row.value ? <div>
				<Link to={"/quoteid/" + row.value} onClick={(event) => { event.preventDefault(); window.open(window.location.origin + "/quoteid/" + row.value); }}>{row.value}</Link>
			</div> : ""
		),
	},
	{
		Header: 'Task',
		accessor: 'type',
	},
	{
		Header: 'State',
		accessor: 'state',
	},
	{
		Header: 'City',
		accessor: 'city',
	},
	{
		Header: 'Employee',
		accessor: 'employeeId',
	},
	{
		Header: 'Start Time',
		accessor: 'startTime',
		Cell: row => (<div>{row.value ? moment(parseInt(row.value)).format('HH:mm') : ""}</div>)
	},
	{
		Header: 'Priority',
		accessor: 'priority',
	},
	{
		Header: 'PE Comment',
		accessor: 'comment',
	},
	{
		Header: 'PM Comment',
		accessor: 'pmComment',
	},
	{
		Header: 'Status',
		accessor: 'statusVal',
	}
];


export const panelInverterStatusColumns = [
	{
		Header: 'Sales Date',
		accessor: 'salesDateEpoch',
		csvAccessor: 'salesDateView',
		Cell: row => (<div>{moment(+(row.value)).format('DD MMM, YYYY')}</div>)
	},
	{
		Header: 'Quote Id',
		accessor: 'quoteId',
		Cell: row => (
			<div>
				<Link to={"/quoteid/" + row.value} onClick={(event) => { event.preventDefault(); window.open(window.location.origin + "/quoteid/" + row.value); }}>{row.value}</Link>
			</div>
		),
	},
	{
		Header: 'Stage',
		accessor: 'stage',
	},
	{
		Header: 'State',
		accessor: 'state',
	},
	{
		Header: 'City',
		accessor: 'city',
	},
	{
		Header: 'Responsible',
		accessor: 'responsible'
	},
	{
		Header: 'Name',
		accessor: 'name',
	},
	{
		Header: 'Contact number',
		accessor: 'number',
	},
	{
		Header: 'Address',
		accessor: 'address',
	},
	{
		Header: 'Pincode',
		accessor: 'pincode',
	},
	{
		Header: 'Promised',
		accessor: 'promised',
	}
];

export const columnsTicket = [
	{
		Header: 'Date',
		accessor: 'epoch',
		Cell: row => (<div>{changeToViewDate(row.value)}</div>)
	},
	{
		Header: row => {
			let { data } = row;
			let quoteList = {};
			data.map(ticketData => {
				quoteList[ticketData.quoteId] = 1;
			})
			return `QuoteId ${Object.keys(quoteList).length}`;
		},
		accessor: 'quoteId',
		Cell: row => (
			<div>
				<Link to={"/quoteid/" + row.value} onClick={(event) => { event.preventDefault(); window.open(window.location.origin + "/quoteid/" + row.value); }}>{row.value}</Link>
			</div>
		),
	},
	{
		Header: 'Name',
		accessor: 'custName',
	},
	{
		Header: 'Phone Number',
		accessor: 'phoneNumber',
	},
	{
		Header: 'City',
		accessor: 'city',
	},
	{
		Header: 'Ticket Id',
		Header: row => {
			let { data } = row;
			return `Ticket Id ${data.length}`;
		},
		accessor: 'ticketId', // String-based value accessors!
		Cell: row => (
			<div>
				<Link to={"/tickets/" + row.value} onClick={(event) => { event.preventDefault(); window.open(window.location.origin + "/tickets/" + row.value); }}>{row.value}</Link>
			</div>
		),
	},
	// {
	// 	Header : 'comment',
	// 	accessor : 'comment',

	// },
	{
		Header: 'Category',
		accessor: 'type',
	},
	{
		Header: 'Sub-Category',
		accessor: 'subType',
	},
	{
		Header: 'Due Date',
		accessor: 'dueDate',
		Cell: row => (<div>{changeToViewDate(row.value)}</div>)
	},
	{
		Header: 'Status',
		accessor: 'status',
	},
	{
		Header: 'Source',
		accessor: 'source'
	},
	{
		Header: 'Team',
		accessor: 'responsibleTeam',
	},
	{
		Header: 'Assigned To',
		accessor: 'assignedToName'
	},
	{
		Header: 'State',
		accessor: 'state'
	},
	{
		Header: 'Stage',
		accessor: 'stage'
	},
	{
		Header: 'Closing Date',
		accessor: 'closeTime',
		Cell: row => (<div>{row.value ? changeToViewDate(row.value) : ""}</div>)
	},
	{
		Header: 'Reason for Close',
		accessor: 'reasonForClose'
	},
	{
		Header: 'Last Updated',
		accessor: 'ticketUpdatedOn',
		Cell: row => (<div>{row.value ? changeToViewDate(row.value) : ""}</div>)
	},
	{
		Header: 'Overdue',
		accessor: 'overdue'
	}
];

export const previousTicketColumns = [
	{
		Header: 'Date',
		accessor: 'epoch',
		Cell: row => (<div>{changeToViewDate(row.value)}</div>)
	},
	{
		Header: 'QuoteId',
		accessor: 'quoteId',
	},
	{
		Header: 'Ticket Id',
		accessor: 'ticketId',

		Cell: row => (
			<div>
				<Link to={"/tickets/" + row.value} onClick={(event) => { event.preventDefault(); window.open(window.location.origin + "/tickets/" + row.value); }}>{row.value}</Link>
			</div>
		),
	},
	{
		Header: 'Category',
		accessor: 'type',
	},
	{
		Header: 'Sub-Category',
		accessor: 'subType',
	}
];


export const columnsCurrent = [
	// {
	// 	Header: 'Date',
	// 	accessor: 'date',
	// 	Cell: row => (<div>{changeToViewDate(row.value)}</div>)
	// },
	{
		Header: 'Time',
		accessor: 'time',
		// Cell: row => (
		// 	<div>
		// 		<Link to={"/quoteid/" + row.value} onClick={(event) => { event.preventDefault(); window.open(window.location.origin + "/quoteid/" + row.value); }}>{row.value}</Link>
		// 	</div>
		// ),
	},
	{
		Header: 'Type',
		accessor: 'call_direction',
	},
	{
		Header: 'Call Stage',
		accessor: 'event_type',
	},
	{
		Header: 'Number',
		accessor: 'customer_number',
	},
	{
		Header: 'Name',
		accessor: 'name',
	},
	{
		Header: 'Quote Id',
		accessor: 'quoteId',
		Cell: row => {
			let quotes = row.original.quoteId;
			return (
				quotes && quotes.length != 0 ? <div>
					{quotes.map(quote => {
						return (<div><a href={"/quoteId/" + quote} target="_blank">{quote} </a></div>)
					})}
				</div> : <div></div>
			)
		}
	},
	{
		Header: 'Paid %',
		accessor: 'payment',
		Cell: row => {
			let payments = row.original.payment;
			return (
				payments && payments.length != 0 ? <div>
					{payments.map(payment => {
						return (<div> {payment}</div>)
					})}
				</div> : <div></div>
			)
		}
	},
	{
		Header: 'City',
		accessor: 'city'
	},
	{
		Header: 'Agent',
		accessor: 'agentName',
	},
	{
		Header: 'Tickets',
		style: { 'whiteSpace': 'unset' },
		accessor: 'tickets',
		Cell: row => {
			let tickets = row.original.tickets;
			let colourCodes = {
				1: 'red',
				2: 'orange',
				3: 'green'
			};
			return (
				tickets && tickets.length != 0 ? <div>
					{tickets.map(ticket => {
						return (<div><a style={{ 'color': colourCodes[ticket.status] }} href={"/tickets/" + ticket.ticketId} target="_blank">{`${ticket.ticketId}`}</a></div>)
					})}
				</div> : <div></div>
			)
		}
	},
	{
		Header: 'Type',
		style: { 'whiteSpace': 'unset' },
		accessor: 'tickets',
		Cell: row => {
			let tickets = row.original.tickets;
			return (
				tickets && tickets.length != 0 ? <div>
					{tickets.map(ticket => {
						return (<div>{`${ticket.type}`}</div>)
					})}
				</div> : <div></div>
			)
		}
	}
];

export const columnsEscalationView = [
	{
		Header: 'Quote Id',
		accessor: 'quoteId'
	},
	{
		Header: 'Type',
		accessor: 'type'
	},
	{
		Header: 'Responsible',
		accessor: 'responsible'
	},
	{
		Header: 'Level',
		accessor: 'level'
	}
];

export const salesAnalyticsTable1 = [
	{
		Header: row => {
			console.log("ROW DATA", row);
			let { data } = row;
			return `State ${data.length}`;
		},
		accessor: 'state'
	},
	{
		Header: row => {
			let { data } = row;
			let total = data.reduce((a, b) => a + (b.saTarget ? parseInt(b.saTarget) : 0), 0);
			return `SA Target ${total}`;
		},
		accessor: 'saTarget'
	},
	{
		Header: row => {
			let { data } = row;
			let total = data.reduce((a, b) => a + (b.saActual ? parseInt(b.saActual) : 0), 0);
			return `SA Actual ${total}`;
		},
		accessor: 'saActual'
	},
	{
		Header: row => {
			let { data } = row;
			let total = data.reduce((a, b) => a + (b.roofTarget ? parseInt(b.roofTarget) : 0), 0);
			return `Roof Target ${total}`;
		},
		accessor: 'roofTarget'
	},
	{
		Header: row => {
			let { data } = row;
			let total = data.reduce((a, b) => a + (b.roofActual ? parseInt(b.roofActual) : 0), 0);
			return `Roof Actual ${total}`;
		},
		accessor: 'roofActual'
	},
	{
		Header: row => {
			let { data } = row;
			let total = data.reduce((a, b) => a + (b.totalSize ? parseInt(b.totalSize) : 0), 0);
			let totalRoof = data.reduce((a, b) => a + (b.roofActual ? parseInt(b.roofActual) : 0), 0);
			let avg = totalRoof > 0 ? parseFloat(total / totalRoof).toFixed(1) : 0;
			return `Avg Size ${avg}`;
		},
		accessor: 'avgSize',
		Cell: row => (<div>{parseFloat(row.value).toFixed(1)}</div>)
	},
	{
		Header: row => {
			let { data } = row;
			let total = data.reduce((a, b) => a + (b.totalSize ? parseInt(b.totalSize) : 0), 0);
			return `Total Size ${total}`;
		},
		accessor: 'totalSize'
	}
];

export const salesAnalyticsTable2 = [
	{
		Header: 'State',
		accessor: 'state'
	},
	{
		Header: 'City',
		accessor: 'city'
	},
	{
		Header: row => {
			let { data } = row;
			let total = data.reduce((a, b) => a + (b.saCount ? parseInt(b.saCount) : 0), 0);
			return `SA Count ${total}`;
		},
		accessor: 'saCount'
	},
	{
		Header: row => {
			let { data } = row;
			let total = data.reduce((a, b) => a + (b.roofCount ? parseInt(b.roofCount) : 0), 0);
			return `Roof Count ${total}`;
		},
		accessor: 'roofCount'
	},
	{
		Header: 'Conversion(%)',
		accessor: 'conv',
		Cell: row => (<div>{parseFloat(row.value).toFixed(1)}</div>)
	}
];


export const salesAnalyticsTable3 = [
	{
		Header: 'Employee',
		accessor: 'employee'
	},
	{
		Header: row => {
			let { data } = row;
			let total = data.reduce((a, b) => a + (b.saCount ? parseInt(b.saCount) : 0), 0);
			return `SA Count ${total}`;
		},
		accessor: 'saCount'
	},
	{
		Header: row => {
			let { data } = row;
			let total = data.reduce((a, b) => a + (b.roofCount ? parseInt(b.roofCount) : 0), 0);
			return `Roof Count ${total}`;
		},
		accessor: 'roofCount'
	},
	{
		Header: 'Conversion(%)',
		accessor: 'conv',
		Cell: row => (<div>{parseFloat(row.value).toFixed(1)}</div>)
	}
];

export const ticketingReportTable = [
	{
		Header: 'Type',
		accessor: 'rowName'
	},
	{
		Header: row => {
			let { data } = row;
			let total = data.reduce((a, b) => a + (b.d1 ? parseInt(b.d1) : 0), 0);
			return `<3 (${total})`;
		},
		accessor: 'd1',
	},
	{
		Header: row => {
			let { data } = row;
			let total = data.reduce((a, b) => a + (b.d2 ? parseInt(b.d2) : 0), 0);
			return `3-7 (${total})`;
		},
		accessor: 'd2'
	},
	{
		Header: row => {
			let { data } = row;
			let total = data.reduce((a, b) => a + (b.d3 ? parseInt(b.d3) : 0), 0);
			return `>=7 (${total})`;
		},
		accessor: 'd3'
	}
];

export const resolvedTickets = [
	{
		Header: 'Type',
		accessor: 'rowName'
	},
	{
		Header: row => {
			let { data } = row;
			let total = data.reduce((a, b) => a + (b.d0 && b.rowName != 'Non-Complaints(Total)' ? parseInt(b.d0) : 0), 0);
			return `<1 (${total})`;
		},
		accessor: 'd0',
	},
	{
		Header: row => {
			let { data } = row;
			let total = data.reduce((a, b) => a + (b.d1 && b.rowName != 'Non-Complaints(Total)' ? parseInt(b.d1) : 0), 0);
			return `1-3 (${total})`;
		},
		accessor: 'd1',
	},
	{
		Header: row => {
			let { data } = row;
			let total = data.reduce((a, b) => a + (b.d2 && b.rowName != 'Non-Complaints(Total)' ? parseInt(b.d2) : 0), 0);
			return `3-7 (${total})`;
		},
		accessor: 'd2'
	},
	{
		Header: row => {
			let { data } = row;
			let total = data.reduce((a, b) => a + (b.d3 && b.rowName != 'Non-Complaints(Total)' ? parseInt(b.d3) : 0), 0);
			return `>=7 (${total})`;
		},
		accessor: 'd3'
	}
];

export const complaintsTracker = [
	{
		Header: 'Sale Month',
		accessor: 'name'
	},
	{
		Header: row => {
			let { data } = row;
			let total = data.reduce((a, b) => a + (b.type1 ? parseInt(b.type1) : 0), 0);
			return `1 (${total})`;
		},
		accessor: 'type1'
	},
	{
		Header: row => {
			let { data } = row;
			let total = data.reduce((a, b) => a + (b.type2 ? parseInt(b.type2) : 0), 0);
			return `2 (${total})`;
		},
		accessor: 'type2'
	},
	{
		Header: row => {
			let { data } = row;
			let total = data.reduce((a, b) => a + (b.type3 ? parseInt(b.type3) : 0), 0);
			return `>=3 (${total})`;
		},
		accessor: 'type3'
	}
];

export const dppColumns = [
	{
		Header: 'Date',
		accessor: 'date',
		Cell: row => (<div>{row.value ? changeToViewDate(row.value) : ''}</div>)
	},
	{
		Header: 'Quote Id',
		accessor: 'quoteId',
		Cell: row => (
			<div>
				<Link to={"/quoteid/" + row.value} onClick={(event) => { event.preventDefault(); window.open(window.location.origin + "/quoteid/" + row.value); }}>{row.value}</Link>
			</div>
		)
	},
	{
		Header: 'Aadhaar Card',
		accessor: 'aadhar',
		Cell: row => {
			let typeData = row.original.aadhar;
			return (
				typeData && typeData.length != 0 ? <div>
					{typeData.map(file => {
						return (<div><a style={{ color: file.userType == 'secondary_cust' ? 'red' : 'blue' }} href={file.url} target="_blank" download>{file.name}</a></div>)
					})}
				</div> : <div></div>
			)
		}
	},
	{
		Header: '6 MBS',
		accessor: 'bank_statement',
		Cell: row => {
			let typeData = row.original.bank_statement;
			return (
				typeData && typeData.length != 0 ? <div>
					{typeData.map(file => {
						return (<div><a style={{ color: file.userType == 'secondary_cust' ? 'red' : 'blue' }} href={file.url} target="_blank" download>{file.name}</a></div>)
					})}
				</div> : <div></div>
			)
		}
	},
	{
		Header: 'Cheques',
		accessor: 'cheques',
		Cell: row => {
			let typeData = row.original.cheques;
			return (
				typeData && typeData.length != 0 ? <div>
					{typeData.map(file => {
						return (<div><a style={{ color: file.userType == 'secondary_cust' ? 'red' : 'blue' }} href={file.url} target="_blank" download>{file.name}</a></div>)
					})}
				</div> : <div></div>
			)
		}
	},
	{
		Header: 'Electricity Bill',
		accessor: 'electricity_bill',
		Cell: row => {
			let typeData = row.original.electricity_bill;
			return (
				typeData && typeData.length != 0 ? <div>
					{typeData.map(file => {
						return (<div><a style={{ color: file.userType == 'secondary_cust' ? 'red' : 'blue' }} href={file.url} target="_blank" download>{file.name}</a></div>)
					})}
				</div> : <div></div>
			)
		}
	},
	{
		Header: 'Pan Card',
		accessor: 'pan',
		Cell: row => {
			let typeData = row.original.pan;
			return (
				typeData && typeData.length != 0 ? <div>
					{typeData.map(file => {
						return (<div><a style={{ color: file.userType == 'secondary_cust' ? 'red' : 'blue' }} href={file.url} target="_blank" download>{file.name}</a></div>)
					})}
				</div> : <div></div>
			)
		}
	},
	{
		Header: '3 MSS',
		accessor: 'salary_slip',
		Cell: row => {
			let typeData = row.original.salary_slip;
			return (
				typeData && typeData.length != 0 ? <div>
					{typeData.map(file => {
						return (<div><a style={{ color: file.userType == 'secondary_cust' ? 'red' : 'blue' }} href={file.url} target="_blank" download>{file.name}</a></div>)
					})}
				</div> : <div></div>
			)
		}
	},
	{
		Header: 'OC',
		accessor: 'ocReport',
		Cell: row => {
			return (<div><a style={{ color: 'blue' }} href={row.value} target="_blank" download>{row.value ? "View" : ""}</a></div>)
		}
	}
];

export const columnsEligibilityData = [
	{
		Header: 'Quote ID',
		accessor: 'quoteId',
		Cell: row => (
			<div>
				<Link to={"/quoteid/" + row.value} onClick={(event) => { event.preventDefault(); window.open(window.location.origin + "/quoteid/" + row.value); }}>{row.value}</Link>
			</div>
		)
	},
	{
		Header: 'Date',
		accessor: 'date',
		Cell: row => (<div>{row.value ? changeToViewDate(row.value) : ''}</div>)
	},
	{
		Header: 'Sales Person',
		accessor: 'salesPerson'
	},
	{
		Header: 'Request Number',
		accessor: 'requestNumber',
	},
	{
		Header: 'First Name',
		accessor: 'firstName'
	},
	{
		Header: 'Last Name',
		accessor: 'surName',
	},
	{
		Header: 'Gender',
		accessor: 'gender'
	},
	{
		Header: 'DOB',
		accessor: 'dob'
	},
	{
		Header: 'Email',
		accessor: 'email'
	},
	{
		Header: 'Phone',
		accessor: 'customerId'
	},
	{
		Header: 'PAN',
		accessor: 'pan',
	},
	{
		Header: 'Monthly Salary',
		accessor: 'monthly_salary_income'
	},
	{
		Header: 'State',
		accessor: 'state',
	},
	{
		Header: 'City',
		accessor: 'city',
	},
	{
		Header: 'Pin Code',
		accessor: 'pincode',
	},
	{
		Header: 'Address',
		accessor: 'address',
	},
	{
		Header: 'Flat No',
		accessor: 'flatNo',
	},
	{
		Header: 'OTP Entered',
		accessor: 'otpEntered'
	},
	{
		Header: 'Bureau File',
		accessor: 'htmlReport',
		Cell: row => (
			<div>
				{row.value ? <Link to="" onClick={(event) => { event.preventDefault(); openHtmlNewTab(row.value) }}>View</Link> : ""}
			</div>
		)
	},
	{
		Header: 'Age',
		accessor: 'age',
	},
	{
		Header: 'Credit Score',
		accessor: 'creditScore',
	},
	{
		Header: 'App Status',
		accessor: 'appStatus',
	}
];

function openHtmlNewTab(html) {
	let myWindow = window.open("", "_blank");
	myWindow.document.write(html);
}

export const columnsConfirmed = [
	{
		Header: 'Date',
		accessor: 'date',
		Cell: row => (<div>{row.value ? changeToViewDate(parseInt(row.value)) : ''}</div>)
	},
	{
		Header: 'Quote Id',
		accessor: 'quoteId'
	},
	{
		Header: 'Name',
		accessor: 'name',
	},
	{
		Header: 'Size',
		accessor: 'size',
	},
	{
		Header: 'City',
		accessor: 'city',
	},
	{
		Header: 'State',
		accessor: 'state'
	},
	{
		Header: 'Responsible No',
		accessor: 'responsibleId'
	},
	{
		Header: 'Responsible',
		accessor: 'responsible'
	},
	{
		Header: 'Elevation Type',
		accessor: 'elevationType',
	},
	{
		Header: 'System Type',
		accessor: 'systemType',
	},
	{
		Header: 'Property Type',
		accessor: 'propertyType',
	},
	{
		Header: 'Total Price',
		accessor: 'totalPrice',
	}
];

export const columnsOutbound = [
	{
		Header: 'Quote Id',
		style: { 'whiteSpace': 'unset' },
		accessor: 'quoteId',
		Cell: row => {
			let quotes = row.original.quoteId;
			return (
				quotes && quotes.length != 0 ? <div>
					{quotes.map(quote => {
						return (<div><a href={"/quoteId/" + quote} target="_blank">{quote}, </a></div>)
					})}
				</div> : <div></div>
			)
		}
	},

	{
		Header: 'Tickets',
		style: { 'whiteSpace': 'unset' },
		accessor: 'tickets',
		Cell: row => {
			let tickets = row.original.tickets;
			return (
				tickets && tickets.length != 0 ? <div>
					{tickets.map(ticket => {
						return (<div><a href={"/tickets/" + ticket} target="_blank">{ticket}, </a></div>)
					})}
				</div> : <div></div>
			)
		}
	},
	{
		Header: 'Priority',
		accessor: 'priority',
		Cell: row => {
			let priority = row.original.priority;
			return (
				priority && priority.length != 0 ? <div>
					{priority.map(prt => {
						return (<div>{prt}, </div>)
					})}
				</div> : <div></div>
			)
		}
	},
	{
		Header: 'Type',
		accessor: 'type',
		Cell: row => {
			let types = row.original.type;
			return (
				types && types.length != 0 ? <div>
					{types.map(type => {
						return (<div>{type}, </div>)
					})}
				</div> : <div></div>
			)
		}
	},
	{
		Header: 'Due Date',
		accessor: 'dueDate',
		Cell: row => {
			let dueDates = row.original.dueDate;
			return (
				dueDates && dueDates.length != 0 ? <div>
					{dueDates.map(dueDate => {
						return (<div>{changeToViewDate(dueDate)}, </div>)
					})}
				</div> : <div></div>
			)
		}
	}
	// {
	// 	Header: 'Agent',
	// 	accessor: 'responsible',
	// 	Cell : row => {
	// 		let responsibles = row.original.responsible;
	// 		return (
	// 			responsibles && responsibles.length != 0 ? <div>
	// 			{responsibles.map(responsible => {
	// 				return (<div>{(responsible)}, </div>)
	// 			})}
	// 			</div> : <div></div>
	// 		)
	// 	}
	// }
];




export const columnUserRoles = [
	{
		Header: 'User Id',
		accessor: 'userid'
	},
	{
		Header: 'User Name',
		accessor: 'name'
	},
	{
		Header: 'App Permission',
		accessor: 'role',
		Cell: row => {
			//return checkAppRole(row);
			let obj = [
				{
					code: 100,
					role: "ZunRoof Customer"
				},
				{
					code: 500,
					role: "ZunRoof Employee"
				},
				{
					code: 900,
					role: "ZunRoof Admin"
				}
			]
			return (<select onChange={function (event) { setUserRole("APP", row.row.userid, obj.find(role => role.code == event.target.value)) }}>
				<option value>--Select--</option>
				{obj.map((role) => {
					if (row.value == role.code) {
						return <option value={role.code} data-role={role.role} selected="selected">{role.role}</option>
					}
					else {
						if (role.code == 900) {
							return <option value={role.code} data-role={role.role} disabled="disabled">{role.role}</option>
						}
						else {
							return <option value={role.code} data-role={role.role}>{role.role}</option>
						}
					}
				})}
			</select>)
		}
	},
	{
		Header: 'Web Permission',
		accessor: 'webRole',
		Cell: row => {
			//return checkWebRole(row);
			let obj = [
				{
					code: 1600,
					role: "Employee"
				},
				{
					code: 1700,
					role: "Project Engineer"
				},
				{
					code: 1800,
					role: "Accounts"
				},
				{
					code: 1900,
					role: "Admin"
				},
				{
					code: 2500,
					role: "Super Admin"
				},
				{
					code: 2600,
					role: "Confidential"
				}
			]
			return (<select onChange={(event) => { setUserRole("WEB", row.row.userid, event.target.value) }}>
				<option value>--Select--</option>
				if(row.original.role >= 500)
				{row.original.role >= 500 ? obj.map((role) => {
					if (row.value == role.code) {
						return <option value={role.code} selected="selected">{role.role}</option>
					}
					else {
						if (role.code >= 2500) {
							return <option value={role.code} disabled="disabled">{role.role}</option>
						}
						else {
							return <option value={role.code}>{role.role}</option>
						}
					}
				}) : ""
				}
			</select>)
		}
	},
	{
		Header: 'Move to Delivery',
		Cell: row => {
			return <button>Move</button>
		}
	}
]

export const qualityReportBasicConfig = [
	{
		Header: 'Date',
		accessor: 'time',
		Cell: row => (<div>{row.value ? changeToViewDate(parseInt(row.value)) : ''}</div>)
	},
	{
		Header: "No.of Panels",
		accessor: "panelcount"
	},
	{
		Header: "Total Wattage",
		accessor: "wastage"
	},
	{
		Header: "Maker of Panels",
		accessor: "panelmaker"
	},
	{
		Header: "DC Wire Brand",
		accessor: "dcwirebrand"
	},
	{
		Header: "DC Wire Size",
		accessor: "dcwiresize"
	},
	{
		Header: "Angles of Panel",
		accessor: "panelangle"
	},
	{
		Header: "Columns",
		accessor: "columns"
	},
	{
		Header: "Rafter",
		accessor: "rafter"
	},
	{
		Header: "Purlin",
		accessor: "purlin"
	},
	{
		Header: "Inverter",
		accessor: "inverter"
	},
	{
		Header: "Phase",
		accessor: "phase"
	},
	{
		Header: "Ac Wire Band",
		accessor: "acwirebrand"
	},
	{
		Header: "Ac Wire Size",
		accessor: "acwiresize"
	},
	{
		Header: "Length",
		accessor: "length"
	},
	{
		Header: "Ac Wire Passed",
		accessor: "acwirepassed"
	},
	{
		Header: "Zunroof Sticker(Installed)",
		accessor: "zunroofsticker"
	},
	{
		Header: "Zunroof RMS(Installed)",
		accessor: "zunroofrms"
	},
	{
		Header: "Inverter country code",
		accessor: "inverter_country_code"
	}
]

export const qualityReportChecksConfig = [
	{
		Header: 'Date',
		accessor: 'time',
		Cell: row => (<div>{row.value ? changeToViewDate(parseInt(row.value)) : ''}</div>)
	},
	{
		Header: "Structure",
		accessor: "structurepass"
	},
	{
		Header: "Foundations",
		accessor: "foundationpass"
	},
	{
		Header: "DCDB",
		accessor: "dcdbnpass"
	},
	{
		Header: "ACDB",
		accessor: "acdbnpass"
	},
	{
		Header: "DC Solar Cable",
		accessor: "dcsolarpass"
	},
	{
		Header: "AC Cable",
		accessor: "acsolarpass"
	},
	{
		Header: "Earthing Cable for LA",
		accessor: "earthcablepass"
	},
	{
		Header: "Earthing(AC/DC)",
		accessor: "earthingacpass"
	},
	{
		Header: "DC cable conducits",
		accessor: "dcconduitspass"
	},
	{
		Header: "Lighting arrestor",
		accessor: "arrestorpass"
	},
	{
		Header: "Chemical earthing pit",
		accessor: "chemearthpass"
	},
	{
		Header: "Nut Bolts, Fasteners (UV protected cable Ties)",
		accessor: "nutboltpass"
	},
	{
		Header: "Inverter copy/Canopy",
		accessor: "invertercopypass"
	}
]

export const qualityReportCommentsConfig = [
	{
		Header: 'Date',
		accessor: 'time',
		Cell: row => (<div>{row.value ? changeToViewDate(parseInt(row.value)) : ''}</div>)
	},
	{
		Header: "Structure",
		accessor: "structurecorrection",
		style: { 'whiteSpace': 'unset' }
	},
	{
		Header: "Panels",
		accessor: "panelscorrection",
		style: { 'whiteSpace': 'unset' }
	},
	{
		Header: "Foundation",
		accessor: "foundationcorrection",
		style: { 'whiteSpace': 'unset' }
	},
	{
		Header: "Earthing",
		accessor: "earthingcorrection",
		style: { 'whiteSpace': 'unset' }
	},
	{
		Header: "Wiring",
		accessor: "wiringcorrection",
		style: { 'whiteSpace': 'unset' }
	},
	{
		Header: "ACDB/DCDB",
		accessor: "acdcdbcorrection",
		style: { 'whiteSpace': 'unset' }
	},
	{
		Header: "LA",
		accessor: "lacorrection",
		style: { 'whiteSpace': 'unset' }
	},
	{
		Header: "Others",
		accessor: "othercorrection",
		style: { 'whiteSpace': 'unset' }
	}
]

export const relatedTicketsColumn = [
	{
		Header: 'Quote Id',
		accessor: 'quoteId'
	},
	{
		Header: 'Ticket Id',
		accessor: 'ticketId',
		Cell: row => (
			<div>
				<Link to={"/tickets/" + row.value} onClick={(event) => { event.preventDefault(); window.open(window.location.origin + "/tickets/" + row.value); }}>{row.value}</Link>
			</div>
		),
	},
	{
		Header: 'Client Name',
		accessor: 'custName'
	},
	{
		Header: 'Category',
		accessor: 'type'
	},
	{
		Header: 'Sub Category',
		accessor: 'subType'
	},
	{
		Header: 'CreatedBy',
		accessor: 'createdBy',
	},
	{
		Header: 'Due Date',
		accessor: 'dueDate',
		Cell: row => (<div>{changeToViewDate(row.value)}</div>)
	},
	{
		Header: 'Removed',
		accessor: 'removed',
		Cell: row => {
			return (
				(row.value == 1) ? 'Yes' : 'No'
			)
		}
	},
	{
		Header: 'Status',
		accessor: 'status'
	},
	{
		Header: 'Responsible',
		accessor: 'assignedTo'
	},
	{
		Header: 'Source',
		accessor: 'source'
	},
	{
		Header: 'Issue Type',
		accessor: 'issueType'
	},
	{
		Header: 'Type of Panel',
		accessor: 'panelType'
	},
	{
		Header: 'Brand of Panel',
		accessor: 'panelBrand'
	},
	{
		Header: 'Brand Confirmation',
		accessor: 'brandConfirm'
	},
	{
		Header: 'Phase',
		accessor: 'inverterPhase'
	},
	{
		Header: 'Brand of Inverter',
		accessor: 'inverterBrand'
	},
	{
		Header: 'Grid Tied',
		accessor: 'gridTied'
	}
]

export const paymentPortalColumns = [
	{
		Header: '',
		accessor: "checkbox",
		filterable: true,
		width: 50,
		sortable: false,
		columns: false,
	},
	{
		Header: 'Date',
		accessor: 'paymentDate',
		Cell: row => (<div>{changeToViewDate(row.value)}</div>)
	},
	{
		Header: 'Business Category',
		accessor: 'category'
	},
	{
		Header: 'Sub Category',
		accessor: 'subCategory'
	},
	{
		Header: 'Customer Name',
		accessor: 'name'
	},
	{
		Header: 'Quote Id',
		accessor: 'id',
		Cell: row => (
			row.value && /^[A-Z]{3}\d{6}$/.test(row.value) ? <div>
				<Link to={"/quoteid/" + row.value} onClick={(event) => { event.preventDefault(); window.open(window.location.origin + "/quoteid/" + row.value); }}>{row.value}</Link>
			</div> : (row.value || "")
		)
	},
	{
		Header: row => {
			// console.log(row)
			let { data } = row;
			let totalAmount = data.reduce((a, b) =>
				a + ((((b.status == "Cleared" || b.status == "Deposited")) && b.amount) ? parseFloat(((b.amount + "").match(/-?\d+|\./g) || [0]).join('')) : 0), 0);

			let x = parseFloat(totalAmount).toFixed(0).toString();
			let getLastThree = x.substring(x.length - 3);
			let remainingNumbers = x.substring(0, x.length - 3);
			if (remainingNumbers != '')
				getLastThree = ',' + getLastThree;
			var amountInINR = remainingNumbers.replace(/\B(?=(\d{2})+(?!\d))/g, ",") + getLastThree;
			return `Amount (${amountInINR})`;
			// return `Amount (${parseFloat(totalAmount).toFixed(1)})`;
		},
		accessor: 'amount',
		sortMethod: (a, b) => parseFloat((((a) + "").match(/\d+|\./g) || [0]).join('')) - parseFloat((((b) + "").match(/\d+|\./g) || [0]).join(''))
	},
	{
		Header: 'Mode',
		accessor: 'mode'
	},
	{
		Header: 'Cheque No.',
		accessor: 'chequeNumber'
	},
	{
		Header: 'Status',
		accessor: 'status'
	},
	{
		Header: 'Cleared On Date',
		accessor: 'clearedOn',
		Cell: row => row.value ? (<div>{changeToViewDate(parseInt(row.value))}</div>) : ""
	},
	{
		Header: 'bank',
		accessor: 'bank'
	},
	{
		Header: 'Employee Name',
		accessor: 'employeeName'
	},
	{
		Header: 'Dealer Code',
		accessor: 'dealerCode'
	},
	{
		Header: 'Ref No',
		accessor: 'referenceNumber'
	},
	{
		Header: "",
		accessor: 'abc',
		width: 250
	},
	// {
	// 	Header: "History",
	// 	accessor: 'history'
	// },
	{
		Header: "Key",
		accessor: 'paymentKey',
		// show: false,
	}
]

export const paymentHistoryColumns = [
	{
		Header: 'Updated By',
		accessor: 'employeeName'
	},
	{
		Header: 'TimeStamp',
		accessor: 'changedOn',
		Cell: row => (<div>{changeToViewDate(parseInt(row.value))}</div>)
	},
	{
		Header: 'Category',
		accessor: 'category',
	},
	{
		Header: 'Field',
		accessor: 'field'
	},
	{
		Header: 'Changed From',
		accessor: 'oldValue'
	},
	{
		Header: 'Changed To',
		accessor: 'newValue'
	}
]

export const v2DashboardColumns = [
	{
		Header: 'Date Of Visit',
		accessor: 'date'
	},
	{
		Header: 'Qoute Id',
		accessor: 'quoteId'
	},
	{
		Header: 'V2 Name',
		accessor: 'name'
	},
	{
		Header: 'POC Name',
		accessor: 'pocName',
	},
	{
		Header: 'POC Contact',
		accessor: 'pocPhoneNumber'
	},
	{
		Header: 'No. Of People',
		accessor: 'partners'
	},
	{
		Header: 'Status',
		accessor: 'status'
	},
	{
		Header: 'Temperature',
		accessor: 'temperature'
	},
	{
		Header: `POC's`,
		accessor: 'pocs'
	},
	{
		Header: 'VendorId',
		accessor: "vendorId",
		show: false,
	}
]

export const pocListColumns = [
	{
		Header: "POC Name",
		accessor: "pocName",
	},
	{
		Header: "POC Contact",
		accessor: "pocId"
	},
	{
		Header: "Has App Access",
		accessor: "hasAppAccess",
		Cell: row => (row.value ? row.value.toString() : "")
	}
]

function checkAppRole(row) {
	switch (row.value) {
		case 500: return "ZunRoof Employee";
		case 600: return "Project Engineer";
		case 700: return "Associate";
		case 800: return "ZunRoof Admin";
		default: return row.value;
	}
}

function checkWebRole(row) {
	switch (row.value) {
		case 500: return "ZunRoof Employee";
		case 600: return "Project Engineer";
		case 700: return "Associate";
		case 800: return "ZunRoof Admin";
		default: return row.value;
	}
}

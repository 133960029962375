export const styleSheet = {
 	show: {
 		display:''
 	},

 	hide: {
 		display:'none'
 	},
 	form: {
 		width:400,
 		margin:'50px auto',
 		padding: 10,
 		borderRadius : 10
 	},
 	head: {
 		'textAlign' : 'center',
 		 margin: '0 0 20px 0'
 	},
 	buttonLogin: {
 		width:'100%'
 	},
 	divStyle : {
	  marginTop: '50px',
	},
	inputStyle : {
		marginTop: '10px'
	},
	tableName : {
		textAlign : 'center',
		width: '360px',
		margin: '0 auto'
	},
	frameOne: {
		marginTop: 50,
		padding:'10px 10px 20px 10px',
 		borderRadius : 10,
	},
	titlePage: {
		//for items
		quote : {
			fontSize: 16,
			width:'90%',
			textAlign:'initial'
		},
		quote1: {
			width:'33%'
		},
		quote2: {
			marginLeft:'30px'
		},
		name : {
			fontSize: 16,
			display: 'flex',
		    flexDirection: 'row-reverse',
		    width: '10%',
		},
		actions:{
			fontSize: 16,
		    width: '50%',
		    display: 'flex',
		    flexDirection: 'row-reverse',
		    edit: {
				paddingTop: 4,
				paddingRight:12
		    }
		},
		display:'flex',
	},
	addComment: {
		width: '100%',
    	textAlign: 'right'
	},
	addCommentQuoteSection: {
		float: 'right'
	},
	plusCircle: {
		fontSize:20,
		color: 'rgba(2, 105, 6, 0.84)'
	},
	plusCircleOnHover: {
		fontSize:22,
		color: 'rgba(36, 197, 42, 0.84)'
	},
	commentForm: {
		width:'100%'
	},
	commentFormButton: {
		margin:10,
		textAlign:'right',
	},
	dropRightArrow: {
		display:'flex'
	}
 }
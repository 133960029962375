import React, { Component } from 'react';
import { CSVLink } from 'react-csv';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Redirect } from 'react-router-dom';

import { styleSheet } from '../styles/styleSheet';

import { fetchfileviews } from '../actions/fileUploadActions/fetchfileviews';
import { fileDownload } from '../actions/fileUploadActions/fileUpload';
import { fetchmapping } from '../actions/fetchmapping';
import { Restrict } from '../components/Restrict';
import { changeToViewDate } from '../helper/dateChanger';


import { FilesView } from '../components/FilesView'
import Modal from 'react-responsive-modal';
import { SalesViewCheckBox } from '../components/SalesViewCheckBox';

class FilesViewMain extends Component {

	constructor(props) {
		super(props);

		this.state = {
			recieved: false,
			openColumn: false,
			choosenColumns: {},
			tableColumns: []
		};
		this.vendor1 = ["NA", "Boond", "Chemitech", "SmartRoof", "Aryav", "Zivah", "SDSL", "Oakridge", "ZunRoof", "SunEnergy", "Volks Energie", "Sirius", "Greenon", "GeoPower", "GM Trading", "Alien", "Adhunik"]
		this.choosenColumn = {};
	}

	componentDidMount() {
		if (this.props.user) {
			if (this.props.user.level >= 1600) {
				this.props.fetchfileviews();
				this.props.fetchmapping();
				this.choosenColumn = localStorage.getItem("column_files") ? JSON.parse(localStorage.getItem("column_files")) : {};
				this.setState({ choosenColumns: this.choosenColumn })
			}
		}
	}

	onOpenColumnModal = () => {
		console.log("Open column modal");
		this.setState({ openColumn: true });
	}

	onCloseColumnModal = () => {
		this.setState({ openColumn: false });
	}

	handleColumnChange = (columnName) => {
		console.log("handleColumnChange", columnName);
		let choosenColumns = { ...this.state.choosenColumns };
		choosenColumns[columnName] = this.state.choosenColumns[columnName] === undefined ? false : !this.state.choosenColumns[columnName];
		this.setState({
			choosenColumns: choosenColumns
		})
		localStorage.setItem("column_files", JSON.stringify(choosenColumns));
	}

	componentWillReceiveProps(props) {

		const { filesdata, states, stages, subsidies, ebissues } = props;
		console.log("--->>", filesdata)
		if (filesdata.data.length > 0 && states && stages && subsidies && ebissues) {
			let columnData = ["Photo","Video","Google Review","Electricity Bill","Panel Delivery Proof","Inverter Delivery Proof","Commissioning Certificate","V2 Invoice","Aadhaar Card","Photo","Property Papers","Rooftop","Other1","Other2","Pan Card","Cancelled Cheque","Panel Layout","Quality Visit 1","Quality Visit 2","Commissioning Form","Completion Certificate","flex1","flex2","flex3","flex4","flex5","cust1","flex7","Stage 1 Approved","Stage 2 Approved","Net Meter Installed","Invoice","Panel Warranty","Inverter Warranty","Payment Receipt","V2 Invoice","AMC Certificate","OC","3D","360","Shadow Profile","Sanction Letter","JCR","Subsidy Closure","Final Token","DCR"];
			let columnDataView = [];
			filesdata.columns.unshift({
				Header: row => {
					return (
						<div>
							<i className="fas fa-cog" style={{ "fontSize": "15px" }} onClick={this.onOpenColumnModal}></i>
						</div>
					)
				},
				accessor: "select",
				width: 50,
				sortable: false,
				filterable: false,
				showDefault: true
			})
			columnData.forEach(val => {
				filesdata.columns.push({
					// Header: val,
					Header: row => {
						let total = (row.data||[]).filter(files => (files||{})[val]).length;
						return(<div>{val} ({total})</div>)
					},
					accessor: val, // String-based value accessors!
					Cell: row => (<div>{row.value ? <Link to="#" onClick={() => { this.props.fileDownload({ userId: this.props.user.phoneNumber, quoteId: row.value.split("__")[0], requestId: row.value.split("__")[1], fileName: row.value.split("__")[2] }) }}>{changeToViewDate(parseInt(row.value.split("__")[3]))}</Link> : ""}</div>),
					sortMethod: (a1, b1) => {
						let a = a1 ? a1.split("__")[3] : 0;
						let b = b1 ? b1.split("__")[3] : 0;
						if (a < b) {
							return 1;
						} else if (b < a) {
							return -1;
						} else {
							return -1;
						}
					},
					customName: val
				});
			});
			this.setState({
				recieved: true,
				tableColumns: filesdata.columns
			})
		}
	}

	render() {
		if (this.props.user === null) {
			return <Redirect to="/login?redirect_uri=files" />;
		}

		if (this.props.user.level < 1600) {
			return (
				<div>
					<Restrict />
				</div>
			)
		}

		let { choosenColumns = {} } = this.state;

		let columnsList = this.state.tableColumns.map(row => {
			if(!row.customName) return row;
			else {
				if(choosenColumns[row.accessor] === false) row.show = false;
				else row.show = true;
				return row;
			}
		})

		if (this.state.recieved) {
			return (
				<div>
					<div className="container-fluid">
						{
							<Modal open={this.state.openColumn} onClose={this.onCloseColumnModal} center>
								<div style={{ width: '600px', height: '500px', display: 'flex' }}>
									<div className="row">
										{
											this.props.filesdata.columns.map((val, index) => {
												if (index && val.customName) {
													return (
														<SalesViewCheckBox
															key={val.accessor}
															showDefault={false}
															columnCheckBoxStatus={choosenColumns[val.accessor] !== false ? true : false}
															handleColumnChange={this.handleColumnChange}
															index={val.accessor}
															label={val.customName}
														/>
													)
												} else {
													return "";
												}
											})
										}
									</div>
								</div>
							</Modal>
						}
						<FilesView column={columnsList}
							data={this.props.filesdata.data}
							user={this.props.user}
							states={this.props.states}
							stateCityMap = {this.props.stateCityMap}
							stages={this.props.stages}
							subsidies={this.props.subsidies}
							ebissues={this.props.ebissues}
							vendor1={this.vendor1} />
					</div>
				</div>
			)
		}

		return (
			<div style={styleSheet.frameOne} className="container-fluid">
				Loading...
			</div>
		);
	}
}


function mapStateToProps(state) {
	return {
		user: state.user,
		filesdata: state.filesdata,
		states: state.mapping.stateMap,
		stages: state.mapping.stageMap,
		subsidies: state.mapping.subsidyMap,
		ebissues: state.mapping.ebissueMap,
		stateCityMap : state.mapping.stateCityMap
	};
};

function mapDispatchToProps(dispatch) {
	return bindActionCreators({ fetchfileviews, fetchmapping, fileDownload }, dispatch);
};

export default connect(mapStateToProps, mapDispatchToProps)(FilesViewMain); 

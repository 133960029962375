import React, { Component } from 'react';
import ReactTable from "react-table";

import  { Filter } from './Filter';

import { dateRange, percentageRange } from '../helper/range';
import { checkExist, checkArrayExist } from '../helper/filterData';
import { styleSheet } from '../styles/styleSheet';
import { changeToViewDate } from '../helper/dateChanger';

export class FilesView extends Component {
	constructor(props) {
	  super(props);
	
	   this.state = {
  			stateList:[],
			stageList:[],
			subsidyList:[],
			stageMulti:[],
			stateMulti:[],
			subsidyMulti:[],
			vendorMulti:[],
			columnMulti:[],
			ebissueMulti:[],
			stateChoosen:null,
			stageChoosen:null,
			subsidyChoosen:null,
			clumnChoosen:null,
			vendorChoosen:null,
			ebissueChoosen:null,
			cityChoosen : [],
			open:false,
			stateChoosenArray:[],
			stageChoosenArray:[],
			subsidyChoosenArray:[],
			columnChoosenArray:[],
			vendorChoosenArray:[],
			ebissueChoosenArray:[],
			viewData:[],
			quoteCount:0,
			systemsize:0,
			column:[],
			startDate:"",
			endDate:"",
			startPercentage:0,
			notificationTitle:null,
			notificationText:null,
			notificationTitleText:null,
			errorData:[],
			showError:false,
			cityList : []
	  };
	}

	componentDidMount() {
		let columnMulti	= [];


		if(this.props.ebissues){
			let { states, stages, ebissues, subsidies } = this.props;

			let size = 0;
			let quoteCount = 0;
			let viewData = [];
			this.props.data.forEach(value => {
				if(this.props.stages[value.stage]!=="Truncated"){
					size += value.size
					quoteCount++;
					viewData.push(value);
				}
			})

			let column = this.props.column.slice();
			column[1].Header = "ID ( "+quoteCount+" )";
			column[3].Header = "Size ( "+size+" )";

				
				

			this.setState({
				subsidyList:this.props.subsidies,
				stateList: states.map(val => {return {value:val, label:val}}),
				stageList: stages.map(val => {return {value:val, label:val}}),
				ebissuesList: ebissues.map((val,index) => {return {value:index, label:val}}),
				subsidiesList: subsidies.map((val,index) => {return {value:index, label:val}}),
				vendorList: ["NA","Boond","Chemitech","SmartRoof","Aryav","Zivah","SDSL","Oakridge","ZunRoof","SunEnergy","Volks Energie","Sirius","Greenon","GeoPower","GM Trading","Alien","Adhunik"].map(val => {return {value:val, label:val}}),
				columnMulti,
				viewData,
				size:size,
				quoteCount:quoteCount,
				column: column
			});
		}
	}


	componentWillReceiveProps(props) {
		const { data, states, stages, subsidies, ebissues } = props;
		if(data.length>0 && states && stages && subsidies && ebissues) {
			this.setState({
				recieved:true
			})
		}
	}


	// FILTER INFORMATION

	handleDateInputChange = (e) => {
		if(e.target.name==="startDate") {
			this.setState({
				[e.target.name]: e.target.value?new Date(e.target.value):0
			})
		}else{
			this.setState({
				[e.target.name]: e.target.value?new Date(e.target.value):9999999999999
			})
		}
	}

	handleInputChange = (e) => {
		this.setState({
			[e.target.name]: e.target.value
		})
	}

	handleMultiSelectChange = (selectedOption, name) => {
		console.log(selectedOption, name);
		switch(name) {
			case "stateChange":
				let {stateCityMap ={}} = this.props;
				let cityList = [];
				(selectedOption||[]).map((data)=>{
					cityList = cityList.concat(stateCityMap[data.label])
				})
				return this.setState({stateChoosen:selectedOption, cityList : cityList.map((val)=>({label : val, value : val}))});
			case "cityChange" : 
				return this.setState({ cityChoosen: selectedOption });
			case "subsidyChange":
				return this.setState({subsidyChoosen:selectedOption});
			case "stageChange":
				return this.setState({stageChoosen:selectedOption});
			case "v1Change":
				return this.setState({vendorChoosen:selectedOption});
			case "ebIssueChange":
				return this.setState({ebissueChoosen:selectedOption});
			default:
				return;
		}
	}

	handleDateInputChange = (e) => {
		if(e.target.name==="startDate") {
			this.setState({
				[e.target.name]: e.target.value?new Date(e.target.value):0
			})
		}else{
			this.setState({
				[e.target.name]: e.target.value?new Date(e.target.value):9999999999999
			})
		}
	}

	
	applyFilter = () => {

		let stateChoosenArray = []
		if(this.state.stateChoosen)
		this.state.stateChoosen.forEach(val=>{
			stateChoosenArray.push(val.label)
		});

		let cityChoosenArray = []
		if(this.state.cityChoosen){
			cityChoosenArray = this.state.cityChoosen.map(val=>val.value)
		}


		let subsidyChoosenArray = [];
		if(this.state.subsidyChoosen)
		this.state.subsidyChoosen.forEach(val=>{
			subsidyChoosenArray.push(val.label)
		})

		let stageChoosenArray = [];
		if(this.state.stageChoosen)
		this.state.stageChoosen.forEach(val=>{
			stageChoosenArray.push(val.label)
		})

		let vendorChoosenArray = [];
		if(this.state.vendorChoosen)
		this.state.vendorChoosen.forEach(val=>{
			vendorChoosenArray.push(val.label)
		})

		let ebissueChoosenArray = [];
		if(this.state.ebissueChoosen)
		this.state.ebissueChoosen.forEach(val=>{
			ebissueChoosenArray.push(val.value)
		})


		let viewData = [];
		let size = 0;
		let quoteCount = 0;

		// RANGE SORTING
console.log("object",cityChoosenArray)
		let startPercentage = this.state.startPercentage;
		let endPercentage 	= this.state.endPercentage;
		this.props.data.forEach(value => {
			if(value.quoteid === "SEP17109"){
				console.log(value.stage);
			}
			if(checkExist(stateChoosenArray,value.state) && checkExist(cityChoosenArray,value.city) && dateRange(value.salesDate,this.state.startDate,this.state.endDate) && percentageRange(value.paymentPercentage,startPercentage,endPercentage) && checkArrayExist(ebissueChoosenArray,value.ebissueList) && checkExist(vendorChoosenArray,value.vendor) && checkExist(subsidyChoosenArray,value.subsidy) && (value.stage!=="11" || (value.stage==="11" && stageChoosenArray.indexOf("11")>-1)) && checkExist(stageChoosenArray,this.props.stages[""+value.stage])) {
				size += value.size;
				quoteCount++;
				viewData.push(value);
			}
		})

		let column = this.props.column.slice();
		column[0].Header = "ID ( "+quoteCount+" )";
		column[2].Header = "Size ( "+size+" )";
		this.setState({
			viewData,
			size,
			quoteCount,
			column
		})
	}


	render() {
		let { handleMultiSelectChange, applyFilter, handleDateInputChange, handleInputChange } = this;
		let { stateList, stageList, vendorList, ebissuesList, subsidiesList,cityList } = this.state;
		return (
			<div>
				<div style={styleSheet.divStyle} className="filter_deals">
					<Filter
						stateList={stateList}
						cityList={cityList}
						stageList={stageList}
						vendorList={vendorList}
						ebissuesList={ebissuesList}
						subsidiesList={subsidiesList}
						handleDateInputChange={handleDateInputChange}
						handleInputChange={handleInputChange}
						handleMultiSelectChange={handleMultiSelectChange}
						applyFilter={applyFilter}
					/>
				</div>
				<div style={styleSheet.tableName}> <h5></h5></div>
			  		<ReactTable
				  		pageSize={10}
				  		filterable={true}
				  		sortable={true}
				  		data={this.state.viewData}
				  		columns={this.props.column}
				  		defaultFilterMethod={(filter, row) => String(row[filter.id]?changeToViewDate(parseInt(row[filter.id].split("__")[3])):"").toLowerCase().indexOf(filter.value.toLowerCase())>-1}/>
			</div>
		);
	}
}
import React, { Component } from 'react';
import ReactTable from "react-table";
import Select from 'react-select';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux'
import { Redirect } from 'react-router-dom';
import Modal from 'react-responsive-modal';

import { fileUpload, fileDownload } from '../actions/fileUploadActions/fileUpload';
import { columnUserRoles } from '../config/column_fields';
import { fetchUserRole } from "../actions/fetchUserRole";
import { addToDelivery } from "../actions/addToDelivery";
import { setUserRole } from "../actions/setUserRole";
import { addNewUser } from "../actions/addNewUser";
import { Restrict } from '../components/Restrict';
import { logincachecheck } from "../actions/auth";
import { Loader } from "../components/Loader.jsx";
import { styleSheet } from '../styles/styleSheet';

import { fetchmainview } from '../actions/fetchmainview';
import { fetchmapping } from '../actions/fetchmapping';
import { addBulkNotification } from '../actions/addBulkNotification';
import { logout} from '../actions/auth';
import { fetchEmailsAnalytics, fetchClientAnalytics, searchData } from '../actions/fetchEmailsAnalytics';
import { setemailstatus } from '../actions/setemailstatus';

class UserRole extends Component {
	constructor(props) {
	  super(props);
		this.state = {
			columnUserRoles : [
				{
					Header: 'User Id',
					accessor: 'userid'
				},
				{
					Header: 'User Name',
					accessor: 'name'
				},
				{
					Header: 'App Permission',
					accessor: 'role',
					Cell : row => {
						let obj = [
							{
								code : 100,
								role : "ZunRoof Customer"
							},
							{
								code : 500,
								role : "ZunRoof Employee"
							},
							{
								code : 900,
								role : "ZunRoof Admin"
							}
						]
						return (<select className="form-control" onChange={(event) => {this.props.setUserRole("APP",row.row.userid, obj.find(role => role.code == event.target.value))
							let newData = this.state.data;
							newData.map((key) => {
								if(key.userid == row.row.userid){
									key.role = event.target.value;
									console.log(key.role);
								}
							})
							this.setState({
								data : newData
							})
						}}>
							<option selected disabled value>--Select--</option>
							{obj.map((role) => {
								if (row.value == role.code) {
									return <option value= {role.code} data-role={role.role} selected="selected">{role.role}</option>
								}
								else {
									if (row.value == 900) {
										return
									}
									if (role.code == 900) {
										return <option value= {role.code} data-role={role.role} disabled="disabled">{role.role}</option>
									}
									else {
										return <option value= {role.code} data-role={role.role}>{role.role}</option>
									}
								}
							})}
						</select>)
					}
				},
				{
					Header: 'Web Permission',
					accessor: 'webRole',
					Cell : row => {
						let obj = [
							{
								code : 0,
								role : "None"
							},
							{
								code : 1600,
								role : "Employee"
							},
							{
								code : 1700,
								role : "Project Engineer"
							},
							{
								code : 1800,
								role : "Accounts"
							},
							{
								code : 1900,
								role : "Admin"
							},
							{
								code : 2500,
								role : "Super Admin"
							},
							{
								code : 2600,
								role : "Confidential"
							}
						]
						return (<select className="form-control" onChange={(event) => {this.props.setUserRole("WEB",row.row.userid, event.target.value)}}>
							<option selected disabled value>--Select--</option>
								{ obj.map((role) => {
									if (row.value == role.code) {
										return <option value= {role.code} selected="selected">{role.role}</option>
									}
									else {
										if (row.value >= 2500) {
											return
										}
										if (role.code >= 2500) {
											return <option value= {role.code} disabled="disabled">{role.role}</option>
										}
										else {
											return <option value= {role.code}>{role.role}</option>
										}
									}
								})
							}
						</select>)
					}
				},
				{
					Header: 'Add to Delivery',
					style : { 'whiteSpace': 'unset'},
					Cell : row => {
						let obj = [
							{
								code : 600,
								role : "ZunRoof Project Engineer"
							},
							{
								code : 700,
								role : "ZunRoof Associate"
							},
							{
								code : 800,
								role : "ZunRoof Admin"
							}
						]
						return (
							<div>
								{row.original.deliveryRole.map( team => {
									return <button className="btn btn-primary">{team.teamName} - {team.roleDescription}</button>
								})}
								<button className="btn btn-success" onClick={this.onOpenModal.bind(this, row.original.userid)}>ADD <i className="fa fa-plus"></i></button>
								<Modal open={this.state.openModal[row.original.userid]} onClose={this.onCloseModal.bind(this, row.original.userid)}>
									Add {row.original.name}({row.original.userid}) to new Delivery team
									<hr/>
									<div id={row.original.userid}>
										<form name={row.original.userid} class="form-control" onSubmit={this.handleDeliveryFormSubmit}>
											{Object.keys(this.state.teams).map((team) => {
												return (
													<div>
														{(row.original.deliveryRole.findIndex(user => user.teamName == this.state.teams[team].teamName) == -1) ? (<div className="form-group">
															<input type="checkbox" id={team} name={row.original.userid} value={team} checked={this.state.deliveryTeams[row.original.userid] && this.state.deliveryTeams[row.original.userid].checked} onChange={this.handleCheckboxChange}/>
															<label><b>{this.state.teams[team].teamName}</b></label>
															<div className="row">
																{this.state.deliveryTeams.hasOwnProperty(row.original.userid) && this.state.deliveryTeams[row.original.userid].hasOwnProperty(team) ? obj.map((role) => {
																	return (
																		<div className="col-md">
																			<input type="radio" name={row.original.userid + "_" + team} id={role} checked={this.state.deliveryTeams[row.original.userid][team] == role.code} value={role.code} onChange={this.handleRadioChange}/>
																			<label>{role.role}</label>
																		</div>
																	)
																}) : ""}
															</div>
														</div>) : ""}
													</div>
												)
											})}
											<div className="form-group">
												<button type="submit" className="btn btn-success">Add Teams</button>
											</div>
										</form>
									</div>
								</Modal>
							</div>
						)
					}
				}
			],
			received : false,
			data : [],
			teams : [],
			success : false,
			openModal : {},
			newUserModal : false,
			newUserForm : {
				name : "",
				number : "",
				empId : ""
			},
			newUserSuccess : false,
			deliveryTeams : {}
		}
	}

	componentDidMount() {
		if(this.props.user){
			if(this.props.user.level>=1600){
				this.props.logincachecheck();
				this.props.fetchUserRole();
				this.props.addToDelivery();
			}
		}
	}

	componentWillReceiveProps(props) {
		const {data, user, teams} = props;
		if(data.length>0){
			this.setState({
				data : data,
				user : user,
				teams :teams,
				received : true
			})
		}
	}

	onOpenModal(userid) {
    this.setState({
			openModal : {
				[userid] : true
			}
		});
  };

  onCloseModal(userid) {
    this.setState({
			openModal : {
				[userid] : false
			}
		});
  };

	handleCheckboxChange = (e) => {
		if (e.target.checked) {
			let deliveryTeams = this.state.deliveryTeams;
			if (!deliveryTeams.hasOwnProperty(e.target.name)) {
				deliveryTeams[e.target.name] = {};
			}
			deliveryTeams[e.target.name][e.target.id] = 600;
			this.setState({
				deliveryTeams : deliveryTeams
			})
		}
		else {
			let deliveryTeams = this.state.deliveryTeams;
			delete deliveryTeams[e.target.name][e.target.id];
			this.setState({
				deliveryTeams : deliveryTeams
			})
		}
		console.log("State",this.state.deliveryTeams[e.target.name]);
	}

	handleRadioChange = (e) => {
		console.log(e.target.value);
		console.log("On Radio teams:", this.state.deliveryTeams);
		let deliveryTeams = this.state.deliveryTeams;
		let user = e.target.name.slice(0,e.target.name.indexOf("_"));
		let team = e.target.name.slice(e.target.name.indexOf("_") + 1);
		deliveryTeams[user][team] = parseInt(e.target.value);
		this.setState({
			deliveryTeams : deliveryTeams
		})
	}

	handleDeliveryFormSubmit = (e) => {
		this.props.addToDelivery(this.state.deliveryTeams[e.target.name], e.target.name);
		let newTeams = Object.keys(this.state.deliveryTeams[e.target.name]).map((team) => {
			let roleObj = {
				600 : "ZunRoof Project Engineer",
				700 : "ZunRoof Associate",
				800 : "ZunRoof Admin"
			}
			let obj = {
				role : this.state.deliveryTeams[e.target.name][team],
				roleDescription: roleObj[this.state.deliveryTeams[e.target.name][team]],
				teamName: this.state.teams[team].teamName
			}
			return obj
		})
		console.log("New Teams", newTeams);
		console.log("Delivery Teams", this.state.deliveryTeams);
		let data = this.state.data;
		let index = data.findIndex(user => user.userid == e.target.name)
		data[index].deliveryRole.push(...newTeams);
		this.setState({
			openModal : {
				[e.target.name] : false,
			},
			data : data
		});
		e.preventDefault();
	}

	onOpenNewUserModal = () => {
    this.setState({
			newUserModal : true
		});
  };

  onCloseNewUserModal = () => {
    this.setState({
			newUserModal : false
		});
  };

	capitalizeString = (str) => {
		str = str.split(" ");
    for (let i = 0, x = str.length; i < x; i++) {
      str[i] = str[i].charAt(0).toUpperCase() + str[i].substr(1);
    }
    return str.join(" ");
	}

	handleNewUserFormNameChange = (e) => {
		let name = this.capitalizeString(e.target.value);
		this.setState( prevState => ({
			newUserForm : {
				...prevState.newUserForm,
				name : name
			}
		}))
	};

	handleNewUserFormNumberChange = (e) => {
		let number = e.target.value;
		this.setState( prevState => ({
			newUserForm : {
				...prevState.newUserForm,
				number : number
			}
		}))
	};

	handleNewUserFormEmpIdChange = (e) => {
		let empId = e.target.value.toUpperCase();
		this.setState( prevState => ({
			newUserForm : {
				...prevState.newUserForm,
				empId : empId
			}
		}))
	};

	handleNewUserFormSubmit = (e) => {
		e.preventDefault();
		if (this.state.data.findIndex(user => user.userid == ("+91" + this.state.newUserForm.number)) == -1) {
			this.props.addNewUser(this.state.newUserForm);
			let data = this.state.data;
			data.push({
				name : this.state.newUserForm.name,
				phone : this.state.newUserForm.number,
				userid : this.state.newUserForm.number,
				role : 500,
				deliveryRole : [],
				webRole : ""
			})
			this.setState({
				newUserModal : false,
				data : data,
				newUserForm : {}
			})
		}
		else {
			alert("User Already Exists.")
		}
	}

	renderDeviceTable() {
		return (
			<div className="container-fluid">
        <ReactTable
          className = "Rtable1"
          defaultPageSize={10}
          filterable
          data={this.state.data}
          columns={this.state.columnUserRoles}
          defaultFilterMethod={(filter, row) => String(row[filter.id]).toLowerCase().indexOf(filter.value.toLowerCase())>-1}
        />
      </div>
		)
	}

	handleShowAllUsers = () => {
		this.props.fetchUserRole(true);
		this.setState({ received: false });
	}

	render() {
		if(this.props.user===null){
			return <Redirect to="/login?redirect_uri=userroles" />;
		}

		if(this.props.user && this.props.user.level<1900) {
			return (
				<div>
					<Restrict />
				</div>
			)
		}

		if(this.state.received) {
			return (
				<div>
					<button className="btn btn-danger" style = {{margin:20}} onClick={this.onOpenNewUserModal}>Add New User <i className="fa fa-plus"></i></button>
					<button className="btn btn-outline-success" style = {{margin:20, float: "right"}} onClick={this.handleShowAllUsers}>Show all users</button>
					<Modal open={this.state.newUserModal} onClose={this.onCloseNewUserModal}>
						<b>Add a new User</b>
						<hr/>
						<form onSubmit={this.handleNewUserFormSubmit} className="form-control">
							<div className="form-group">
								<label>Name</label>
								<input className="form-control" type="text" value={this.state.newUserForm.name} onChange={this.handleNewUserFormNameChange} required/>
							</div>
							<div className="form-group">
								<label>Phone Number (10 Digit)</label>
								<div className="input-group">
									<span className="input-group-addon"></span>
									<input className="form-control" type="text" value={this.state.newUserForm.number} onChange={this.handleNewUserFormNumberChange} maxLength="10" minLength="10" pattern="[0-9]{10}" placeholder="9876543210" required/>
								</div>
							</div>
							<div className="form-group">
								<label>Employee Id (Complete ZunRoof Id)</label>
								<input className="form-control" type="text" value={this.state.newUserForm.empId} onChange={this.handleNewUserFormEmpIdChange} placeholder="ZR001" pattern="ZR[0-9]+" required/>
							</div>
							<div className="form-group">
								<button type="submit" className="btn btn-success">Add User</button>
							</div>
						</form>
					</Modal>
					<div>
						{this.renderDeviceTable()}
					</div>
				</div>
			)
		}

		return (
			<div style = {styleSheet.frameOne} className="container-fluid">
				<Loader />
			</div>
		);
	}

}

function mapStateToProps({user, userRole, setUserRole, newUser, addToDelivery}) {
	let {data, teams} = userRole;
	console.log("New Data", data);
	let {success} = setUserRole;
	console.log("USER StateToProps:",user);
	return {
		user : user,
		data : data,
		teams : teams,
		success : success,
		newUserSuccess : newUser.success
	};
};

function mapDispatchToProps(dispatch) {
	return bindActionCreators({ fetchUserRole, logincachecheck, setUserRole, addNewUser, addToDelivery}, dispatch);
};

export default connect(mapStateToProps, mapDispatchToProps)(UserRole);

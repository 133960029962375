import { FETCHING_USERROLE_START,
  FETCHING_USERROLE_SUCCESS } from './actiontypes';

import { db } from '../connection';
import { fetch } from '../helper/firebaseFunctions';

export function fetchUserRole(fetchAllUsersFlag = false) {
  return (dispatch) => {
    dispatch({type : FETCHING_USERROLE_START});
    mergeData(fetchAllUsersFlag).then((finalObj) => {
      dispatch({type : FETCHING_USERROLE_SUCCESS, payload : finalObj });
    })
  }
}

async function mergeData(fetchAllUsersFlag) {
  let employeeList = await fetchEmployeeList();
  let teams = await fetchDeliveryMapping();
  let userRoles = await Promise.all(employeeList.map(async user => {
    user.webRole = await fetchWebRole(user.userid);
    user.name = user.fname + " " + user.lname;
    user.deliveryRole = [];
    if(user.role <= 100 && user.webRole < 1600) user.isCustomer = true;
    Object.values(teams).forEach((team) => {
      if(Object.keys(team.members).includes(user.userid)){
        let obj = {
          teamName : team.teamName,
          role : team.members[user.userid].role,
          roleDescription : team.members[user.userid].roleDescription
        }
        user.deliveryRole.push(obj);
      }
    })
    return user;
  }))
  if(!fetchAllUsersFlag) {
    userRoles = userRoles.filter(row => !row.isCustomer)
  }
  let finalObj = {userRoles : userRoles, teams: teams};
  return finalObj;
}

async function fetchEmployeeList() {
  let snapshot = await db.ref("/employeelist").once("value");
  let employeeList = snapshot.val();
  return Object.values(employeeList);
}

async function fetchWebRole(user) {
  try{
    let snapshot = await db.ref("/webDelivery/userrolemapping").child(user).once("value");
    let webRole = snapshot.val();
    return webRole;
  }
  catch(e){
    console.log("user", user);
    return '';
  }
}

async function fetchDeliveryMapping() {
  let snapshot = await db.ref("/delivery/teamtoEmployeeMapping").once("value");
  let teams = snapshot.val();
  // Object.keys(teams).forEach((team) => {
  //   let teamNumber = parseInt(team.slice(4))
  //   if( teamNumber < 8 || teamNumber > 15){
  //     delete teams[team];
  //   }
  // })
  return teams;
}
